import React from "react";
import { MdChevronRight } from "react-icons/md";
import { BsBookmarkCheckFill } from "react-icons/bs";

interface SectionProps {
  isDone: boolean;
  sectionHeader: string;
  sectionText: React.ReactNode;
  ctaText?: string;
  subText?: React.ReactNode;
  onPressed: () => void;
}

const Section: React.FC<SectionProps> = ({
  isDone,
  sectionHeader,
  sectionText,
  ctaText,
  subText,
  onPressed,
}) => {
  // Check if sectionText is a BenefitsBuilder component
  const isBenefitsBuilder =
    React.isValidElement(sectionText) && "benefitsSummary" in sectionText.props;

  return (
    <div
      data-testid="section-container"
      onClick={onPressed}
      className={`border border-gray-200 rounded-lg h-full flex flex-col w-full cursor-pointer transition-all duration-200 overflow-hidden shadow-lg ${
        isDone
          ? "hover:border-green-500 hover:shadow-lg"
          : "hover:border-blue-700"
      }`}
    >
      <div className="bg-blue-700 text-white">
        <h3 className="text-lg font-semibold lg:py-2 px-3 py-1 flex items-center gap-2">
          <BsBookmarkCheckFill className="text-xl text-orange-300" />
          {sectionHeader}
        </h3>
      </div>
      <div className="p-2 lg:p-4 flex flex-col flex-1">
        <div className="flex flex-col gap-2 h-full">
          <div className="flex items-start justify-between gap-2">
            <div className="flex-1">
              {isBenefitsBuilder ? (
                React.cloneElement(sectionText as React.ReactElement, {
                  useButtons: false,
                })
              ) : (
                <div>{sectionText}</div>
              )}
            </div>
            <MdChevronRight className="text-2xl text-orange-700 mt-1 flex-shrink-0" />
          </div>
          {subText && (
            <div className="text-center text-sm font-bold">{subText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section;
