import { useState, useEffect } from 'react';
import api from '../utils/api';
import { UIUtils } from '../utils/uiUtils';

// Keep track of in-flight requests
const pendingRequests: { [key: string]: Promise<any> } = {};

interface Article {
  id: number;
  title: string;
  authorName: string;
  authorImageUrl: string;
  articleImageUrl: string;
  summary: string;
  articleUrl: string;
  authorBioUrl: string;
  readTime: string;
  publishedTime: string;
  updatedTime: string;
}

export const useArticles = (userGoalStatement: string) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchArticles = async () => {
      // Create or reuse the request promise
      if (!pendingRequests[userGoalStatement]) {
        pendingRequests[userGoalStatement] = api.fetchArticles(userGoalStatement)
          .then(response => {
            delete pendingRequests[userGoalStatement];
            return response;
          })
          .catch(err => {
            delete pendingRequests[userGoalStatement];
            throw err;
          });
      }

      try {
        const response = await pendingRequests[userGoalStatement];
        if (isMounted) {
          setArticles(response.data.articles);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          UIUtils.trackException("Fetch articles", err);
          setError("Failed to load articles");
          setLoading(false);
        }
      }
    };

    fetchArticles();

    return () => {
      isMounted = false;
    };
  }, [userGoalStatement]);

  return { articles, loading, error };
};
