import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { fetchExtLandingData } from '../utils/api';
import { UIUtils } from '../utils/uiUtils';
import { Button } from '../components/Button';
import Loader from '../components/Loader';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';

interface ExtLandingData {
  eligibility: boolean;
  leadDetails?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    occupancy?: string;
    monthly_income?: string;
    dob?: Date;
    marital_status?: string;
    no_of_children?: string;
  };
}

const ExtLanding: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [leadData, setLeadData] = useState<ExtLandingData | null>(null);
  const navigate = useNavigate();
  const { setIsVisible } = useBottomNavBar();
  const uid = searchParams.get('amj');

  useEffect(() => {
    setIsVisible(false);
    return () => setIsVisible(true);
  }, [setIsVisible]);

  useEffect(() => {
    const fetchData = async () => {
      if (!uid) {
        UIUtils.showMessageOnSnackBar('Invalid URL');
        navigate("/")
        setLoading(false);
        return;
      }

      try {
        const response = await fetchExtLandingData(uid);
        const data: ExtLandingData = response.data;
        
        if (data.eligibility && data.leadDetails) {
          if (data.leadDetails.dob) {
            data.leadDetails.dob = new Date(data.leadDetails.dob)
          }
          setLeadData(data);
        } else {
          // Redirect to organic signup
          navigate("/");
          return;
        }
      } catch (err) {
        UIUtils.showMessageOnSnackBar('Something went wrong. Please try again later.');
        UIUtils.trackException('External landing fetch error', err);
        navigate("/")
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uid, navigate]);

  if (loading) {
    return <Loader message="Loading..." />;
  }

  if (!leadData) return null;

  const handleSignupClick = () => {
    navigate('/', { 
      state: { 
        leadDetails: leadData.leadDetails,
        fromExtLanding: true
      } 
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      {/* Header Section */}
      <div className="flex items-center justify-between pb-4 border-b max-w-2xl w-full">
        <div className="flex items-center">
          <h1 className="text-xl">Welcome to Afforda</h1>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="max-w-2xl w-full px-4 pt-8">
        {/* Hero Section */}
        <div className="bg-blue-50 rounded-xl p-6 mb-6">
          <h2 className="text-3xl font-bold text-blue-700 mb-4">
          Your loan search didn’t find a match — see how Afforda can help.
          </h2>
          <p className="text-lg text-blue-600 mb-4">
            Afforda is 100% Free - No Subscriptions, No Hidden Fees.
          </p>
          <p className="text-neutral-600 mb-6">
            Tell us what you need. 
            We will show you options to help you move forward.
          </p>
          <Button
            text="Get Started - It's Free"
            onClick={handleSignupClick}
            type="button"
            className="w-full bg-blue-500 hover:bg-blue-600"
          />
        </div>

        {/* Benefits Section */}
        <div className="bg-neutral-50 rounded-xl p-6 mb-6">
          <h3 className="text-2xl font-bold text-neutral-800 mb-4">
            Take Action Today
          </h3>
          <div className="bg-white rounded-lg p-6 shadow-sm border border-neutral-200 mb-4">
            <p className="text-lg font-semibold text-neutral-800 mb-2">
              Check if you're eligible for benefits - £22.7 billion goes unclaimed every year.
            </p>
            <p className="text-neutral-600 mb-4">
              Complete our quick Benefits check form to see what you may be entitled to.
            </p>
            <Button
              text="See What You May Qualify For"
              onClick={handleSignupClick}
              type="button"
              className="w-full bg-orange-500 hover:bg-orange-600"
            />
          </div>
        </div>

        {/* Features Section */}
        <div className="mb-8">
          <h3 className="text-2xl font-bold text-neutral-800 mb-6">
            How Afforda Helps
          </h3>
          <div className="space-y-4">
            <div className="bg-white rounded-lg p-4 border border-neutral-200">
              <h4 className="font-semibold text-blue-600 mb-2">Quick Benefits Check</h4>
              <p className="text-neutral-600">See what support you could claim in a few clicks.</p>
            </div>
            <div className="bg-white rounded-lg p-4 border border-neutral-200">
              <h4 className="font-semibold text-blue-600 mb-2">Instant Savings</h4>
              <p className="text-neutral-600">Get exclusive discounts at your favourite high street stores.</p>
            </div>
            <div className="bg-white rounded-lg p-4 border border-neutral-200">
              <h4 className="font-semibold text-blue-600 mb-2">Credit Options</h4>
              <p className="text-neutral-600">We compare products from over 15 lenders to see if it's possible to get you an offer. <span className="font-bold">Representative 1333%APR</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtLanding;
