import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { useUser } from "../contexts/UserContext";
import wordpressApi from "../utils/wordpressApi";
import config from "../configs/config";

interface MenuItem {
  title: string;
  url: string;
}

interface MenuItems {
  [key: string]: MenuItem[];
}

interface HeaderProps {}

interface Section {
  title: string;
  key: string;
}

interface MainMenuItem {
  title: string;
  key?: string;
  url?: string;
  isFromWPList?: boolean;
  sections?: Section[];
}

const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { clearUserData } = useUser();
  const [menuItems, setMenuItems] = useState<MenuItems>({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedMenus, setExpandedMenus] = useState<string[]>(["Guides"]);

  // Lock body scroll when menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      const items = await wordpressApi.fetchAllSubMenuItems();
      setMenuItems(items);
    };
    fetchMenuItems();
  }, []);

  const handleAuthAction = () => {
    setIsMobileMenuOpen(false);
    if (user) {
      signOut();
      clearUserData();
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const handleSettingsClick = () => {
    setIsMobileMenuOpen(false);
    navigate("/preferences");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    // When opening mobile menu, set Guides as expanded by default
    setExpandedMenus((prev) => (isMobileMenuOpen ? [] : ["Guides"]));
  };

  const toggleSubMenu = (parentTitle: string) => {
    setExpandedMenus((prev) => {
      if (parentTitle === "Guides") {
        // For Guides panel, close everything if it's being closed
        return prev.includes(parentTitle) ? [] : ["Guides"];
      } else {
        // For inner panels:
        // If clicking an already open panel, close it but keep Guides open
        if (prev.includes(parentTitle)) {
          return ["Guides"];
        }
        // If clicking a new panel, keep only Guides and the new panel open
        return ["Guides", parentTitle];
      }
    });
  };

  const mainMenuItems: MainMenuItem[] = [
    {
      title: "Guides",
      key: "Guides",
      sections: [
        { title: "Benefits", key: "Benefits" },
        { title: "Personal finance", key: "Personal Finance" },
        { title: "Loans", key: "Loans" },
        { title: "Insights", key: "Insights" },
      ],
    },
    {
      title: "FAQs",
      url: `${config.WORDPRESS_URL}/faqs/`,
      isFromWPList: true,
    },
    {
      title: "Contact us",
      url: `/contact-us`,
    },
  ];

  return (
    <>
      <div className="hidden lg:block top-0 left-0 right-0 z-50 bg-blue-700 h-10" />
      <header className="lg:top-8 top-0 left-0 right-0 z-50 bg-white border-b-4 border-orange-700 mb-5 lg:mb-12">
        <div className="max-w-7xl mx-auto px-4 lg:py-2 py-3 relative">
          <div className="flex items-center justify-between h-auto">
            <div className="flex items-center flex-1">
              <a href="/customer-home" className="flex-shrink-0">
                <img
                  src="https://afforda.co.uk/wp-content/uploads/2024/12/logo-orange-a.svg"
                  alt="Afforda Logo"
                  className="h-9"
                />
              </a>
              <div className="h-10 w-px bg-neutral-400 mx-6"></div>
              <nav className="hidden lg:block flex-1">
                <ul className="flex justify-center space-x-8">
                  {mainMenuItems.map((item) => (
                    <li key={item.title} className="relative group">
                      {item.isFromWPList ? (
                        // eslint-disable-next-line
                        <a
                          href={item.url}
                          target="_blank"
                          className="py-2 inline-block text-lg text-txt-base hover:text-blue-700 transition-colors duration-200"
                        >
                          {item.title}
                        </a>
                      ) : item.url && !item.sections ? (
                        // Internal link without dropdown
                        <a
                          href={item.url || "/"}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(item.url || "/");
                          }}
                          className="py-2 inline-block text-lg text-txt-base hover:text-blue-700 transition-colors duration-200"
                        >
                          {item.title}
                        </a>
                      ) : (
                        <>
                          <button
                            className="py-2 inline-flex items-center text-lg text-txt-base hover:text-blue-700 transition-colors duration-200 group"
                            aria-expanded={expandedMenus.includes(
                              item.key || ""
                            )}
                            aria-haspopup="true"
                          >
                            {item.title}
                            {item.sections && (
                              <svg
                                className="w-5 h-5 ml-1.5 transition-transform duration-200 group-hover:rotate-180 text-txt-base hover:text-blue-700"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            )}
                          </button>
                          {item.sections && (
                            <div
                              className="fixed left-0 right-0 bg-white shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out z-50"
                              role="menu"
                              aria-label="Desktop submenu"
                              style={{ top: "104px" }}
                              onMouseEnter={() => {
                                document.body.style.overflow = "hidden";
                              }}
                              onMouseLeave={() => {
                                document.body.style.overflow = "";
                              }}
                            >
                              <div className="w-full py-4">
                                <div className="container mx-auto max-w-screen-xl px-8">
                                  <div className="grid grid-cols-4 lg:gap-8 md:gap-4">
                                    {item.sections.map((section) => (
                                      <div
                                        key={section.key}
                                        className="pb-4 pt-2"
                                      >
                                        {/* eslint-disable-next-line */}
                                        <a
                                          href={`${
                                            config.WORDPRESS_URL
                                          }/${section.key.toLowerCase()}/`}
                                          target="_blank"
                                          className="text-txt-base hover:text-blue-700 text-lg font-semibold mb-4 block transition-colors duration-200 hover:underline"
                                        >
                                          {section.title}
                                        </a>
                                        <div className="relative">
                                          <ul
                                            className="space-y-4 max-h-[280px] overflow-y-auto pr-3 mb-4"
                                            style={{
                                              position: "relative",
                                            }}
                                            aria-label={`${section.title} section submenu`}
                                            onMouseEnter={(e) => {
                                              const el = e.currentTarget;
                                              el.style.overflowY = "auto";
                                            }}
                                            onMouseLeave={(e) => {
                                              const el = e.currentTarget;
                                              el.style.overflowY = "hidden";
                                            }}
                                            onWheel={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            {menuItems[section.key]?.map(
                                              (subItem: MenuItem) => (
                                                <li key={subItem.title}>
                                                  {/* eslint-disable-next-line */}
                                                  <a
                                                    href={subItem.url}
                                                    target="_blank"
                                                    className="block text-[15px] leading-relaxed text-txt-base hover:text-blue-700 hover:underline transition-colors duration-200"
                                                  >
                                                    {subItem.title}
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                          <div className="sticky bottom-0 pt-4 border-t border-neutral-400 bg-white">
                                            {/* eslint-disable-next-line */}
                                            <a
                                              href={`${
                                                config.WORDPRESS_URL
                                              }/${section.key.toLowerCase()}/`}
                                              target="_blank"
                                              className="inline-flex items-center text-txt-base hover:text-blue-700 text-[15px] font-medium transition-colors duration-200 hover:underline gap-2"
                                            >
                                              Discover more guides
                                              <svg
                                                className="w-4 h-4 ml-1"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth={2}
                                                  d="M19 9l-7 7-7-7"
                                                  transform="rotate(-90 12 12)"
                                                />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="flex items-center space-x-4">
              {user && (
                <div className="relative group hidden lg:block">
                  <button
                    onClick={handleSettingsClick}
                    className="p-2"
                    aria-label="Profile settings"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                  <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-neutral-600 text-white text-sm rounded opacity-0 invisible group-hover:opacity-100 transition-all duration-200 whitespace-nowrap">
                    Profile settings
                  </div>
                </div>
              )}
              <button
                onClick={handleAuthAction}
                className="hidden lg:block bg-orange-500 text-white px-6 py-2.5 rounded-lg font-bold text-base transition-all duration-200 hover:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-500 focus:ring-offset-2 custom-shadow"
              >
                {user ? "Logout" : "Login"}
              </button>
              <button
                className="block lg:hidden"
                onClick={toggleMobileMenu}
                aria-label="Toggle mobile menu"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div
            className="lg:hidden fixed inset-0 z-50"
            role="dialog"
            aria-label="Mobile menu"
          >
            <div
              className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity duration-300"
              onClick={toggleMobileMenu}
              role="button"
              aria-label="Close mobile menu"
            ></div>
            <div
              className={`relative h-full w-full max-w-md ml-auto bg-white shadow-xl flex flex-col transform transition-transform duration-300 ease-in-out ${
                isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <div className="flex justify-end items-center pt-4 pr-4">
                <button
                  onClick={toggleMobileMenu}
                  className="pt-1"
                  aria-label="Close menu"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <nav className="flex-1 overflow-y-auto px-4">
                <ul>
                  <li>
                    <div>
                      <button
                        onClick={() => toggleSubMenu("Guides")}
                        className="flex justify-between items-center w-full text-left py-1 text-lg text-txt-base focus:outline-none transition-colors duration-150"
                        aria-label="Guides section"
                      >
                        Guides
                        <svg
                          className={`w-4 h-4 transition-transform ${
                            expandedMenus.includes("Guides")
                              ? "transform rotate-180"
                              : ""
                          }`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                      {expandedMenus.includes("Guides") && (
                        <div className="pl-4 mb-2">
                          {mainMenuItems
                            .find((item) => item.key === "Guides")
                            ?.sections?.map((section) => (
                              <div
                                key={section.key}
                                className="border-b border-neutral-200"
                                data-testid={`inner-panel-container-${section.key}`}
                              >
                                <button
                                  onClick={() => toggleSubMenu(section.key)}
                                  className="flex justify-between items-center w-full text-left py-2 text-base text-txt-base focus:outline-none transition-colors duration-150"
                                  aria-label={`${section.title} section`}
                                >
                                  {section.title}
                                  <svg
                                    className={`w-4 h-4 transition-transform ${
                                      expandedMenus.includes(section.key)
                                        ? "transform rotate-180"
                                        : ""
                                    }`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M19 9l-7 7-7-7"
                                    />
                                  </svg>
                                </button>
                                {expandedMenus.includes(section.key) && (
                                  <div>
                                    <div className="max-h-[200px] overflow-y-auto">
                                      <ul className="space-y-1">
                                        {menuItems[section.key]?.map(
                                          (subItem: MenuItem) => (
                                            <li key={subItem.title}>
                                              <a
                                                href={subItem.url}
                                                className="block px-4 py-1 text-txt-base transition-colors duration-150 rounded-lg"
                                                aria-label={`${subItem.title} link`}
                                                onClick={toggleMobileMenu}
                                              >
                                                {subItem.title}
                                              </a>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                    <div className="pb-2"></div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </li>
                  {mainMenuItems
                    .filter((item) => item.isFromWPList)
                    .map((item) => (
                      <li key={item.title}>
                        {/* eslint-disable-next-line */}
                        <a
                          href={item.url}
                          target="_blank"
                          className="block py-1 text-lg text-txt-base rounded-lg transition-colors duration-150"
                          aria-label={`${item.title} link`}
                          onClick={toggleMobileMenu}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  {/* Internal links without dropdowns */}
                  {mainMenuItems
                    .filter(
                      (item) => !item.isFromWPList && item.url && !item.sections
                    )
                    .map((item) => (
                      <li key={item.title}>
                        <a
                          href={item.url || "/"}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(item.url || "/");
                            toggleMobileMenu();
                          }}
                          className="block py-1 text-lg text-txt-base rounded-lg transition-colors duration-150"
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  {user && (
                    <button
                      onClick={handleSettingsClick}
                      className="w-full py-1 text-left text-lg text-txt-base rounded-lg transition-colors duration-150 focus:outline-none"
                    >
                      Profile Settings
                    </button>
                  )}
                </ul>
              </nav>
              <div className="p-4 space-y-2">
                <button
                  onClick={handleAuthAction}
                  // className="bg-orange-500 text-white px-6 py-2.5 rounded-lg hover:bg-orange-600 transition-colors duration-200 w-full"
                  className="bg-orange-500 text-white px-6 py-2.5 rounded-lg font-bold text-base transition-all duration-200 hover:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-500 focus:ring-offset-2 custom-shadow w-full"
                >
                  {user ? "Logout" : "Login"}
                </button>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
