import React from "react";
import { useNavigate } from "react-router-dom";
import { BenefitsSummary } from "../utils/types";
import { Button } from "./Button";

export interface BenefitsBuilderProps {
  benefitsSummary: BenefitsSummary | null;
  useButtons?: boolean;
}

const BenefitsBuilder: React.FC<BenefitsBuilderProps> = ({
  benefitsSummary,
  useButtons = false,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (benefitsSummary?.stage === "full") {
      navigate("/benefits-summary");
    } else if (benefitsSummary) {
      navigate("/full-benefits");
    } else {
      navigate("/getting-started");
    }
  };

  const getButtonText = () => {
    if (benefitsSummary?.stage === "full") {
      return "View details";
    } else if (benefitsSummary) {
      return "Use the calculator";
    }
    return "Go to BenefitGlance";
  };

  if (benefitsSummary?.stage === "full") {
    return (
      <div
        className="flex flex-col"
        onClick={useButtons ? undefined : handleNavigation}
      >
        <p className="text-base my-2">
          You could be eligible for up to{" "}
          <span className="font-bold">
            £{benefitsSummary.monthlyBenefitsAmount}
          </span>{" "}
          of benefits per month
        </p>
        {useButtons && (
          <div className="flex justify-center mt-4">
            <Button text={getButtonText()} onClick={handleNavigation} />
          </div>
        )}
      </div>
    );
  }

  if (benefitsSummary) {
    return (
      <div
        className="flex flex-col"
        onClick={useButtons ? undefined : handleNavigation}
      >
        {benefitsSummary.extraMonthlyBenefitsAmount > 0 ? (
          <p className="text-base mt-2">
            Based on the details you've shared, you could be qualified to
            receive up to{" "}
            <span className="font-bold">
              £{benefitsSummary.extraMonthlyBenefitsAmount}
            </span>{" "}
            per month in benefits.
          </p>
        ) : (
          <p className="text-base mt-2">
            Based on the details you've shared, you are not currently eligible
            to receive any benefits.
          </p>
        )}
        <p className="mt-2.5 text-base">
          This is a quick estimate from the information provided.
        </p>
        <p className="mt-2.5 text-base">
          Our free benefits calculator will help you determine exactly which
          government benefits you could be eligible for and how to go about
          applying for them. It's super easy to use and should take less than 5
          minutes to complete!
        </p>
        {useButtons && (
          <div className="flex justify-center mt-4">
            <Button text={getButtonText()} onClick={handleNavigation} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="flex flex-col"
      onClick={useButtons ? undefined : handleNavigation}
    >
      <p className="text-base my-2">
        Use Afforda's BenefitGlance tool to find out if you could be eligible
        for receiving some benefits
      </p>
      {useButtons && (
        <div className="flex justify-center mt-4">
          <Button text={getButtonText()} onClick={handleNavigation} />
        </div>
      )}
    </div>
  );
};

export default BenefitsBuilder;
