interface CookieOptions {
  expiryDays?: number;
  domain?: string;
  sessionOnly?: boolean;
}

// For backward compatibility
export const setCookie = (
  name: string,
  value: string,
  expiryDaysOrOptions: number | CookieOptions = 1
) => {
  // Handle legacy usage
  if (typeof expiryDaysOrOptions === 'number') {
    const date = new Date();
    date.setTime(date.getTime() + (expiryDaysOrOptions * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    return;
  }

  // Handle new options-based usage
  const options: CookieOptions = expiryDaysOrOptions;
  let cookieString = `${name}=${value};path=/`;
  
  // Add expiry if not session-only
  if (!options.sessionOnly && options.expiryDays) {
    const date = new Date();
    date.setTime(date.getTime() + (options.expiryDays * 24 * 60 * 60 * 1000));
    cookieString += `;expires=${date.toUTCString()}`;
  }
  
  // Add domain if specified
  if (options.domain) {
    cookieString += `;domain=${options.domain}`;
  }
  
  document.cookie = cookieString;
};

export const deleteCookie = (name: string, domain?: string) => {
  let cookieString = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  
  if (domain) {
    cookieString += `;domain=${domain}`;
  }
  
  document.cookie = cookieString;
};

export const getAffordaDomain = (): string => {
  const hostname = window.location.hostname;
  return hostname.includes('afforda.co.uk') ? '.afforda.co.uk' : hostname;
};

export const getCookie = (name: string): string | null => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};
