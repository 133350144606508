import { RouteObject } from 'react-router-dom';
import React, { Suspense } from 'react';
import { URL_PATHS } from './utils/routeUtils';
import Welcome from './pages/Welcome';
import EmailVerification from './pages/EmailVerification';
import CustomerHome from './pages/CustomerHome';
import QuickResults from './pages/QuickResults';
import BenefitsSummary from './pages/BenefitsSummary';
import BenefitsWrapper from './components/BenefitsWrapper';
import GettingStarted from './pages/GettingStarted';
import FullBenefits from './pages/FullBenefits';
import LoansLanding from './pages/LoansLanding';
import UserGoal from './pages/UserGoal';
import Recommendations from './pages/Recommendations';
import ReviewActions from './pages/ReviewActions';
import LoanApplication from './pages/loan-apply/LoanApplication';
import PreferenceCenter from './pages/PreferenceCenter';
import PrivacyConsent from './pages/PrivacyConsent';
import Error404 from './pages/Error404';
import Unsubscribe from './pages/Unsubscribe';
import LeadLanding from './pages/DeclineLanding';
import ExtLanding from './pages/ExtLanding';
import Loader from './components/Loader';
import Logoff from './pages/Logoff';
import ContactUs from './pages/ContactUs';
import { lazy } from 'react';

// Lazy loaded components
const Discounts = lazy(() => import('./pages/Discounts'));
const Regret = lazy(() => import('./pages/Regret'));

type CustomRouteObject = RouteObject & {
  protected?: boolean;
};

const routes: CustomRouteObject[] = [
  {
    path: URL_PATHS.HOME,
    element: React.createElement(Welcome),
  },
  {
    path: URL_PATHS.LOGIN,
    element: React.createElement(Welcome),
  },
  {
    path: URL_PATHS.EMAIL_VERIFICATION,
    element: React.createElement(EmailVerification),
  },
  {
    path: URL_PATHS.LEAD_LANDING,
    element: React.createElement(LeadLanding),
    protected: false,
  },
  {
    path: URL_PATHS.EXT_LANDING,
    element: React.createElement(ExtLanding),
    protected: false,
  },
  {
    path: URL_PATHS.PRIVACY_CONSENT,
    element: React.createElement(PrivacyConsent),
    protected: true,
  },
  {
    path: URL_PATHS.CUSTOMER_HOME,
    element: React.createElement(CustomerHome),
    protected: true,
  },
  {
    path: URL_PATHS.GETTING_STARTED,
    element: React.createElement(BenefitsWrapper, {
      defaultComponent: React.createElement(GettingStarted)
    }),
    protected: true,
  },
  {
    path: URL_PATHS.QUICK_RESULTS,
    element: React.createElement(QuickResults),
    protected: true,
  },
  {
    path: URL_PATHS.BENEFITS_SUMMARY,
    element: React.createElement(BenefitsWrapper, {
      defaultComponent: React.createElement(BenefitsSummary)
    }),
    protected: true,
  },
  {
    path: URL_PATHS.FULL_BENEFITS,
    element: React.createElement(FullBenefits),
    protected: true,
  },
  {
    path: URL_PATHS.LOANS_LANDING,
    element: React.createElement(LoansLanding),
    protected: true,
  },
  {
    path: URL_PATHS.APPLY,
    element: React.createElement(LoanApplication),
    protected: true,
  },
  {
    path: URL_PATHS.ASK_AFFORDA,
    element: React.createElement(UserGoal),
    protected: true,
  },
  {
    path: URL_PATHS.SUGGESTIONS,
    element: React.createElement(Recommendations),
    protected: true,
  },
  {
    path: URL_PATHS.REVIEW_ACTIONS,
    element: React.createElement(ReviewActions),
    protected: true,
  },
  {
    path: URL_PATHS.PREFERENCES,
    element: React.createElement(PreferenceCenter, {
      config: {
        showMarketingEmail: true,
        showPushNotification: false,
        showAudioRecording: false,
        showLocationPermission: false
      }
    }),
    protected: true,
  },
  {
    path: URL_PATHS.DISCOUNTS,
    element: React.createElement(
      Suspense,
      { fallback: React.createElement(Loader, { message: 'Loading discounts...' }) },
      React.createElement(Discounts)
    ),
    // No protected flag - hybrid page accessible to both logged-in and non-logged-in users
  },
  {
    path: URL_PATHS.REGRET,
    element: React.createElement(
      Suspense,
      { fallback: React.createElement(Loader, { message: 'Loading...' }) },
      React.createElement(Regret)
    ),
    protected: true,
  },
  {
    path: URL_PATHS.UNSUBSCRIBE,
    element: React.createElement(Unsubscribe),
  },
  {
    path: URL_PATHS.LOGOFF,
    element: React.createElement(Logoff),
    protected: false,
  },
  {
    path: URL_PATHS.CONTACT_US,
    element: React.createElement(ContactUs),
    protected: false,
  },
  {
    path: URL_PATHS.ERROR_404,
    element: React.createElement(Error404),
  },
];

export default routes;
