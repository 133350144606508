import React from "react";
import { Button } from "./Button";

interface ImportantInfoPopupProps {
  onClose: () => void;
  onContinue: () => void;
  onGoBack: () => void;
  isInitial?: boolean;
}

const ImportantInfoPopup: React.FC<ImportantInfoPopupProps> = ({
  onClose,
  onContinue,
  onGoBack,
  isInitial = false,
}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div className="absolute inset-0 pointer-events-none flex items-center justify-center p-4">
        <div
          className="bg-white rounded-2xl w-full max-w-2xl pointer-events-auto flex flex-col"
          style={{ height: "80vh" }}
        >
          {/* Fixed Header */}
          <div className="flex-none p-4 border-b">
            <h2 className="text-2xl font-bold">Important Information</h2>
          </div>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-4">
            <div className="space-y-4">
              <h3 className="font-bold text-lg mb-2">
                About loans with Afforda
              </h3>
              <p className="mb-4">
                GAIN Credit LLC, trading as Afforda is a credit broker, not a
                lender. We connect you with credit options from various finance
                providers.
              </p>
              <p className="mb-4">
                We compare products from over 15 lenders to see if it's possible
                to get you an offer.
              </p>
              <p className="mb-4">
                <span className="font-bold">Contact address:</span> Afforda, PO
                Box 10756, Leicester, LE3 4GX
              </p>

              <div className="bg-orange-50 border border-l-8 border-orange-700 p-4 shadow-lg p-4 my-4">
                <h3 className="font-bold text-lg mb-2">
                  Representative example
                </h3>
                <p>
                  Given a Representative APR of{" "}
                  <span className="font-bold">1333%</span>, if you borrow{" "}
                  <span className="font-bold">£300</span> over{" "}
                  <span className="font-bold">6 months</span>, at{" "}
                  <span className="font-bold">292.0%</span> p.a. (fixed), you
                  would owe <span className="font-bold">6</span> equal
                  repayments of <span className="font-bold">£96.66</span>.
                </p>
                <p className="pt-3">
                  You would repay <span className="font-bold">£579.96</span> in
                  total.
                </p>
              </div>

              <h3 className="font-bold text-lg">Important considerations</h3>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Not all individuals will be approved for credit. An offer of
                  credit is subject to status and meeting the lender's criteria.
                </li>
                <li>
                  If you are offered a rate, this will depend on your unique
                  situation.
                </li>
                <li>
                  You may be able to borrow between £100 and £5,000, with
                  repayment terms from over 61 days to 60 months.
                </li>
              </ul>

              <h3 className="font-bold text-lg">Fees and commissions</h3>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  Afforda does not charge you for using our service - we receive
                  a commission from lenders or companies we introduce you to.
                </li>
                <li>
                  The amount we receive depends on the product and amount
                  borrowed.
                </li>
                <li>
                  Any commission we receive does not affect the price you pay.
                </li>
              </ul>

              <h3 className="font-bold text-lg">Other services</h3>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  If we are unable to find you a loan or line of credit, we may
                  transfer you directly to alternative firms and services such
                  as credit builder tools.
                </li>
                <li>
                  These tools may incur a monthly fee if you choose to use them.
                </li>
              </ul>
            </div>
          </div>

          {/* Fixed Button Container */}
          <div className="flex-none p-4 border-t bg-white rounded-b-2xl">
            <div className="flex justify-center space-x-4">
              <Button text="Go back" onClick={onGoBack} />
              <Button text="Continue" onClick={onContinue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportantInfoPopup;
