import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/auth';
import { useUser } from '../contexts/UserContext';
import Header from '../components/Header';
import BottomNavBar from '../components/BottomNavBar';

const Logoff: React.FC = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { clearUserData } = useUser();

  useEffect(() => {
    if (user) {
      signOut();
      clearUserData();
      navigate('/');
    } else {
      navigate('/');
    }
  }, [user, signOut, clearUserData, navigate]);

  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="flex items-center justify-center min-h-[calc(100vh-144px)]">
        <div className="w-16 h-16 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
      <BottomNavBar />
    </div>
  );
};

export default Logoff;
