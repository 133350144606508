export type Intent = 'sa' | 'ben' | 'ln';

export const IntentSection = {
  sa: 'saveOnShopping',
  ben: 'checkBenefits',
  ln: 'loan',
  ds: 'retailDiscounts'
} as const;

export const isValidIntent = (intent: string | null): intent is Intent => {
  return intent === 'sa' || intent === 'ben' || intent === 'ln' || intent === 'ds';
};
