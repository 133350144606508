import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api, { postEmailForVerification } from "../utils/api";
import { UIUtils } from "../utils/uiUtils";
import DateInput from "../components/DateInput";
import { subYears, format } from "date-fns";
import { Button } from "../components/Button";
import config from "../configs/config";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { Resources } from "../utils/resources";
import { validateRedirectUrl } from "../utils/routeUtils";
import { setCookie } from "../utils/cookieUtils";
import { isValidIntent, Intent } from "../utils/types/intent";

interface LocationState {
  email?: string;
  fromLogin?: boolean;
  intent?: string;
  leadDetails?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    occupancy?: string;
    monthly_income?: string;
    dob?: Date | null;
    marital_status?: string;
    no_of_children?: string;
    // Add other fields that match signup form
  };
  fromExtLanding?: boolean;
}

type Step = "welcome" | "account" | "goals" | "details";

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: Date | null;
  goal: string;
  maritalStatus: string;
  numberOfChildren: string;
  occupancy: string;
  monthlyIncome: string;
  agreedToPrivacyPolicy: boolean;
  receiveUpdates: boolean;
}

const Welcome: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();
  const { setIsVisible } = useBottomNavBar();
  const [currentStep, setCurrentStep] = useState<Step>("welcome");
  const [isLoading, setIsLoading] = useState(false);

  // Refs for focus management
  const emailInputRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const goalTextareaRef = useRef<HTMLTextAreaElement>(null);
  const maritalStatusRef = useRef<HTMLSelectElement>(null);

  // Focus management when step changes
  useEffect(() => {
    switch (currentStep) {
      case "welcome":
        emailInputRef.current?.focus();
        break;
      case "account":
        firstNameRef.current?.focus();
        break;
      case "goals":
        goalTextareaRef.current?.focus();
        break;
      case "details":
        maritalStatusRef.current?.focus();
        break;
    }
  }, [currentStep]);

  // Get and validate redirectTo from URL params
  const params = new URLSearchParams(location.search);
  const redirectTo = validateRedirectUrl(params.get("redirectTo"));
  const directApply = params.get("dap");
  const intent = params.get("intent") || "";

  const [userData, setUserData] = useState<UserData>({
    firstName: state?.leadDetails?.first_name || "",
    lastName: state?.leadDetails?.last_name || "",
    email: state?.leadDetails?.email || state?.email || "",
    dateOfBirth: state?.leadDetails?.dob || null,
    goal: "",
    maritalStatus: state?.leadDetails?.marital_status || "",
    numberOfChildren: state?.leadDetails?.no_of_children || "",
    occupancy: state?.leadDetails?.occupancy || "",
    monthlyIncome: state?.leadDetails?.monthly_income || "",
    agreedToPrivacyPolicy: false,
    receiveUpdates: false,
  });

  useEffect(() => {
    setIsVisible(false);
    // Store direct apply parameter in cookie if present
    if (directApply === "x29") {
      setCookie("afforda_direct_apply", "true");
    }
    return () => setIsVisible(true);
  }, [setIsVisible, directApply]);

  // Sanitize and validate goal text
  const sanitizeGoal = (text: string): string => {
    // Remove any HTML tags and decode entities to prevent XSS
    const div = document.createElement("div");
    div.textContent = text;
    const sanitized = div.textContent || "";

    // Truncate to max length
    const MAX_GOAL_LENGTH = 250;
    return sanitized.slice(0, MAX_GOAL_LENGTH);
  };

  const handleInputChange = (field: keyof UserData, value: any) => {
    // Apply sanitization for goal field
    const finalValue = field === "goal" ? sanitizeGoal(value) : value;
    setUserData((prev) => ({
      ...prev,
      [field]: finalValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (currentStep === "welcome") {
      const trimmedEmail = userData.email.trim();
      if (!trimmedEmail) {
        UIUtils.showMessageOnSnackBar("Please enter your email address");
        return;
      }

      setIsLoading(true);
      try {
        // Try to login with email
        const response = await postEmailForVerification(trimmedEmail, intent);

        if (response.data.status === "USER_NOT_FOUND") {
          // Email doesn't exist, proceed to signup
          handleInputChange("email", trimmedEmail);
          setCurrentStep("account");
        } else if (response.data.reference_id) {
          // Email exists and verification code sent
          const navigationState = {
            from: redirectTo,
            intent: isValidIntent(intent) ? (intent as Intent) : undefined,
            directApply: directApply === "x29",
          };
          navigate(`/email-verification/${response.data.reference_id}`, {
            state: navigationState,
          });
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error: any) {
        UIUtils.showMessageOnSnackBar(
          error.response?.data?.message ||
            "An error occurred. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
      return;
    }

    if (currentStep === "account") {
      if (!userData.dateOfBirth) {
        UIUtils.showMessageOnSnackBar("Please enter your date of birth");
        return;
      }
      // Check for userInput when transitioning to goals step
      const userInput = params.get("userInput");
      if (userInput) {
        try {
          const decodedInput = decodeURIComponent(userInput);
          handleInputChange("goal", decodedInput);
        } catch (error) {
          console.error("Error decoding goal input:", error);
          // Continue without setting the goal if decoding fails
        }
      }
      setCurrentStep("goals");
      return;
    }

    if (currentStep === "goals") {
      if (!userData.goal) {
        UIUtils.showMessageOnSnackBar(
          "Please describe your financial challenge or goal"
        );
        return;
      }
      setCurrentStep("details");
      return;
    }
    setIsLoading(true);
    try {
      const signUpData: any = {
        first_name: userData.firstName,
        email: userData.email,
        dob: format(userData.dateOfBirth!, "yyyy-MM-dd"),
        privacy_consent: userData.agreedToPrivacyPolicy,
        marketing_email_dnc_flag: !userData.receiveUpdates,
        created_by: "web-app",
        intent: params.get("intent") || ""
      };

      // Only include additional_data if user has filled out details
      if (
        userData.occupancy ||
        userData.numberOfChildren ||
        userData.maritalStatus ||
        userData.monthlyIncome ||
        userData.goal
      ) {
        signUpData.additional_data = {
          type_of_housing: userData.occupancy || "",
          childrenNum: parseInt(userData.numberOfChildren) || 0,
          marital_status: userData.maritalStatus || "",
          income_amount: parseInt(userData.monthlyIncome) || 0,
          user_goal: userData.goal || "",
        };
      }

      const response = await api.signUpUser(signUpData);

      if (response.data.reference_id) {
        const navigationState = {
          from: redirectTo,
          intent: isValidIntent(intent) ? (intent as Intent) : undefined,
          directApply: directApply === "x29",
        };
        navigate(`/email-verification/${response.data.reference_id}`, {
          state: navigationState,
        });
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error: any) {
      UIUtils.showMessageOnSnackBar(
        error.response?.data?.message ||
          "Oops! Sign-up failed. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderWelcomeStep = () => (
    <div>
      <img
        src="https://afforda.co.uk/wp-content/uploads/2024/12/afforda-logo-orange.png"
        alt="Afforda logo"
        className="w-40 md:w-52 mx-auto transition-transform hover:scale-105 duration-300"
      />
      <h1 className="text-center mb-4">
        Helping you afford the things you need.
      </h1>
      <div className="w-40 lg:w-48 h-40 lg:h-48 mx-auto rounded-[40px] overflow-hidden bg-gray-50 border-4 border-blue-400 shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-[1.02]">
        <img
          src="https://afforda.co.uk/wp-content/uploads/2025/03/afforda-customer.png"
          alt="Afforda Customer"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="mb-6 bg-blue-50 p-2 mt-4 rounded-lg">
        <ul className="text-left space-y-2 max-w-md mx-auto">
          <li className="flex items-center">
            <p>
              <span className="font-semibold">Existing user? </span> We'll log
              you in securely.
            </p>
          </li>
          <li className="flex items-center">
            <p>
              <span className="font-semibold">New here? </span> Create your free
              account in just a few steps.
            </p>
          </li>
        </ul>
        <p className="mt-2">
          No passwords, no hassle – just smart financial solutions tailored to
          you.{" "}
        </p>
      </div>
      <input
        ref={emailInputRef}
        data-sensitive="true"
        type="email"
        id="email"
        name="email"
        value={userData.email}
        onChange={(e) => handleInputChange("email", e.target.value)}
        required
        className="form-input w-full transition-all duration-300 focus:ring-2 focus:ring-orange-500"
        placeholder="Enter your email"
        disabled={isLoading}
        autoComplete="email"
        aria-label="Email"
      />
      <p className="text-neutral-500 text-sm">
        We'll send you a secure login code.
      </p>
    </div>
  );

  const renderAccountStep = () => (
    <>
      <h2 className="text-xl font-semibold mb-2">Create an account</h2>
      <div className="space-y-6">
        <div className="flex">
          <input
            ref={firstNameRef}
            type="text"
            id="firstName"
            value={userData.firstName}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
            required
            className="form-input w-full"
            placeholder="First name"
            aria-label="First name"
          />
          <span className="ml-1 text-red-500" aria-hidden="true">
            *
          </span>
        </div>
        <div className="flex">
          <input
            type="text"
            id="lastName"
            value={userData.lastName}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
            className="form-input w-full"
            placeholder="Last name"
            aria-label="Last name"
          />
          <span className="ml-1 text-red-500 invisible" aria-hidden="true">
            *
          </span>
        </div>
        <div className="flex">
          <DateInput
            dataSensitive="true"
            selected={userData.dateOfBirth}
            onChange={(date) => handleInputChange("dateOfBirth", date)}
            name="dob"
            maxDate={subYears(new Date(), 18)}
            placeholderText="Date of birth (DD/MM/YYYY)"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            className="form-input w-full"
            dateFormat="dd/MM/yyyy"
            aria-label="Date of birth"
          />
          <span className="ml-1 text-red-500" aria-hidden="true">
            *
          </span>
        </div>
        <div className="space-y-4">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                id="receiveUpdates"
                checked={userData.receiveUpdates}
                onChange={(e) =>
                  handleInputChange("receiveUpdates", e.target.checked)
                }
                className="form-checkbox"
              />
            </div>
            <label
              htmlFor="receiveUpdates"
              className="ml-2 text-sm text-secondary"
            >
              We would like to keep you updated on loan offers, products and
              services we offer through Gain Credit LLC (trading as Afforda) by
              email. You can unsubscribe at any time by going to your profile
              settings.
            </label>
          </div>

          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                id="agreedToPrivacyPolicy"
                required
                checked={userData.agreedToPrivacyPolicy}
                onChange={(e) =>
                  handleInputChange("agreedToPrivacyPolicy", e.target.checked)
                }
                className="form-checkbox"
              />
            </div>
            <label
              htmlFor="agreedToPrivacyPolicy"
              className="ml-2 text-sm text-secondary"
            >
              I agree to the{" "}
              <a
                href={`${config.WORDPRESS_URL}/terms-of-use`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:text-blue-500 underline"
              >
                Terms of use
              </a>{" "}
              and consent to the{" "}
              <a
                href={`${config.WORDPRESS_URL}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:text-blue-500 underline"
              >
                Privacy policy
              </a>
              , which explains how my personal information will be processed and
              shared. This includes any sensitive information I may choose to
              provide, such as information about my health, and I'm aware that I
              can withdraw this consent at any time.
            </label>
            <span className="ml-1 text-red-500" aria-hidden="true">
              *
            </span>
          </div>
        </div>
      </div>
    </>
  );

  const renderGoalsStep = () => (
    <>
      <h2 className="text-xl font-semibold mb-2">
        Tell us what we can help with
      </h2>
      <p className="text-secondary mb-6">
        We ask because it'll help us give you a better Afforda experience. The
        more details you provide, the better options Afforda can offer you.
      </p>
      <div className="flex">
        <textarea
          ref={goalTextareaRef}
          value={userData.goal}
          onChange={(e) => handleInputChange("goal", e.target.value)}
          placeholder="Please describe your financial challenge or goal (max 250 characters)"
          className="form-input w-full h-32"
          required
          maxLength={250}
        />
        <span className="ml-1 text-red-500" aria-hidden="true">
          *
        </span>
      </div>
    </>
  );

  const renderDetailsStep = () => (
    <>
      <h2 className="text-xl font-semibold mb-2">
        Tell us a little more about you
      </h2>
      <p className="text-secondary mb-3">
        By answering a few questions, you can find out how much additional
        government support you could be eligible for, along with personalised
        suggestions and analysis tailored to your financial situation.
      </p>
      <div className="space-y-6">
        <div>
          <select
            ref={maritalStatusRef}
            value={userData.maritalStatus}
            onChange={(e) => handleInputChange("maritalStatus", e.target.value)}
            className="form-input w-full"
            aria-label="Marital status"
          >
            <option value="">Marital status</option>
            {Object.entries(Resources.maritalStatusOptions).map(
              ([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>
        <div>
          <select
            value={userData.numberOfChildren}
            onChange={(e) =>
              handleInputChange("numberOfChildren", e.target.value)
            }
            className="form-input w-full"
            aria-label="Number of children"
          >
            <option value="">Number of children</option>
            {Object.entries(Resources.numberOfChildrenOptions).map(
              ([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>
        <div>
          <select
            value={userData.occupancy}
            onChange={(e) => handleInputChange("occupancy", e.target.value)}
            className="form-input w-full"
            aria-label="Type of occupancy"
          >
            <option value="">Type of occupancy</option>
            {Object.entries(Resources.housingTypeOptions).map(
              ([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>
        <div>
          <div className="relative">
            <span className="absolute left-3 top-3">£</span>
            <input
              type="number"
              value={userData.monthlyIncome}
              onChange={(e) => {
                const value = e.target.value;
                if (!value || (Number(value) >= 0 && Number(value) <= 15000)) {
                  handleInputChange("monthlyIncome", value);
                }
              }}
              className="form-input w-full pl-8"
              min="0"
              max="15000"
              placeholder="Monthly income (after tax)"
              aria-label="Monthly income"
            />
          </div>
        </div>
        <p className="text-sm text-secondary">
          Or you can continue now and fill it out later.
        </p>
      </div>
    </>
  );

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <main
        className={`flex-grow container mx-auto ${
          currentStep === "welcome" ? "pt-2" : "pt-4"
        }`}
      >
        {isLoading ? (
          <div className="text-center">
            {currentStep === "details"
              ? "Setting up your account..."
              : "Please wait..."}
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            {currentStep !== "welcome" && (
              <>
                <div className="flex items-center justify-between pb-3">
                  <div className="flex items-center">
                    <button
                      type="button"
                      onClick={() => {
                        if (currentStep === "account") {
                          setCurrentStep("welcome");
                        } else if (currentStep === "goals") {
                          setCurrentStep("account");
                        } else if (currentStep === "details") {
                          setCurrentStep("goals");
                        }
                      }}
                      className="mr-4 text-gray-600 hover:text-gray-900"
                    >
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <h1 className="text-md">Sign-up</h1>
                  </div>
                </div>
                <div className="h-2 w-full bg-gray-200 rounded mb-3">
                  <div
                    className="h-full bg-green-500 rounded transition-all duration-300"
                    style={{
                      width:
                        currentStep === "account"
                          ? "33%"
                          : currentStep === "goals"
                          ? "66%"
                          : "100%",
                    }}
                  />
                </div>
              </>
            )}

            <div className="space-y-6">
              {currentStep === "welcome" && renderWelcomeStep()}
              {currentStep === "account" && renderAccountStep()}
              {currentStep === "goals" && renderGoalsStep()}
              {currentStep === "details" && renderDetailsStep()}
            </div>

            <div className="mt-6">
              <Button
                text={
                  currentStep === "details" || "welcome" ? "Continue" : "Next"
                }
                onClick={() => {}}
                type="submit"
                className="w-full"
                disabled={isLoading}
              />
            </div>
          </form>
        )}
      </main>
    </div>
  );
};

export default Welcome;
