import { useForm } from '../contexts/FormContext';
import { useUser } from '../contexts/UserContext';
import { useAuth } from './auth';
import { CustomerData, UserGoal, BenefitsSummary, BenefitsBreakdown } from './types';
import { UIUtils } from './uiUtils';
import api from './api';
import { useLocation } from 'react-router-dom';
import { Intent, isValidIntent } from './types/intent';
import { setCookie, getAffordaDomain } from './cookieUtils';

export const usePostLoginProcessor = () => {
  const { updateField } = useForm();
  const { updateUserData } = useUser();
  const { setUser } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const directApply = searchParams.get("dap") === "x29"; // encoded parameter for direct-to-apply
  const TOKEN_STORAGE_KEY = 'afforda_auth_token';
  const processPostLogin = (
    jsonData: any,
    accessToken: string,
    loginType: string
  ): { redirectTo: string, state?: { activeIntent: Intent } } => {
    const customerData = jsonData as CustomerData;
    let userGoal = null;
    let benefitsSummary = null;
    let benefitsBreakdown = null;

    // Get redirect URL from location state if available
    const redirectState = location.state as { from?: string } | null;
    let redirectTo = redirectState?.from || '/customer-home';

    if (jsonData.goal_data && jsonData.goal_data.response && !jsonData.goal_data.response.error) {
      userGoal = jsonData.goal_data as UserGoal;
    }

    if (jsonData.benefits_summary && Object.keys(jsonData.benefits_summary).length > 0) {
      benefitsSummary = jsonData.benefits_summary as BenefitsSummary;
    }

    if (jsonData.benefits_breakdown && Object.keys(jsonData.benefits_breakdown).length > 0) {
      benefitsBreakdown = jsonData.benefits_breakdown as BenefitsBreakdown;
    }

    // Set token in API first
    api.setToken(accessToken);

    // Then update auth context with the access token
    setUser({ accessToken });

    // Store token in localStorage
    localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
    // Set session cookie with correct domain
    setCookie("aat", accessToken, {
      sessionOnly: true,
      domain: getAffordaDomain()
    });

    // Pre-populate form data
    UIUtils.prefillFormData(customerData, updateField);

    // Update UserContext with the data
    updateUserData({
      user: customerData.basic_profile,
      userGoal,
      benefitsSummary,
      benefitsBreakdown,
      marketing_email_dnc_flag: jsonData.dnc_preferences?.marketing_email_dnc_flag ?? false
    });

    // Get intent from location state
    const params = new URLSearchParams(location.search);
    const locationState = location.state as { intent?: Intent } | null;
    const intent = locationState?.intent || params.get("intent");
    const hasValidIntent = intent && isValidIntent(intent);
    // Override redirect based on required flows
    if (jsonData.need_privacy_policy_consent) {
      redirectTo = '/privacy-consent';
    } else if (loginType === 'lead_id') {
      redirectTo = '/suggestions';
    } else if (directApply && redirectTo === '/loans-landing') {
      // Skip loans landing and go directly to apply if the encoded flag is set
      redirectTo = '/apply';
    } else if (!redirectState?.from && hasValidIntent && userGoal !== null) {
      // If no specific redirect but has intent, and has got a user goal, then go to suggestions
      redirectTo = '/suggestions';
      return { redirectTo, state: { activeIntent: intent } };
    // } else if (!redirectState?.from && userGoal !== null) {
    //   // This is so that we send users from magic link (who have intent dropped) to suggestions
    //   redirectTo = '/suggestions';
    } else if (!redirectState?.from && !userGoal) {
      // Only redirect to ask-afforda if no intent and no goal
      redirectTo = '/ask-afforda';
    }

    // Return with state if there's a valid intent
    return hasValidIntent && intent ? 
      { redirectTo, state: { activeIntent: intent } } : 
      { redirectTo };
  };

  return { processPostLogin };
};
