import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { Resources } from "../utils/resources";
import UserGoalInput from "../components/UserGoalInput";
import BottomNavBar from "../components/BottomNavBar";
import Loader from "../components/Loader";

const ReviewActions: React.FC = () => {
  const navigate = useNavigate();
  const { userGoal } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isThumbsUpSelected, setIsThumbsUpSelected] = useState(false);
  const [isThumbsDownSelected, setIsThumbsDownSelected] = useState(false);
  const [showTextBox, setShowTextBox] = useState(false);
  const [isRetailPurchaseOffered, setIsRetailPurchaseOffered] = useState(false);
  const [isLoanOffered, setIsLoanOffered] = useState(false);
  const [isImmediateNeed, setIsImmediateNeed] = useState(false);
  const [needSignPosting, setNeedSignPosting] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!userGoal) {
      throw new Error("User goal is missing");
    }

    setIsRetailPurchaseOffered(
      userGoal.goal_actions.some(
        (action) => action.goal_action === "retailDiscounts"
      )
    );
    setIsLoanOffered(
      userGoal.goal_actions.some((action) => action.goal_action === "loan")
    );
    setIsImmediateNeed(userGoal.response.timeframe === "immediate");
    setNeedSignPosting(userGoal.response.risk_score >= Resources.riskThreshold);
  }, [userGoal]);

  const toggleTextBox = (show: boolean) => {
    setShowTextBox(show);
    setIsThumbsUpSelected(false);
    setIsThumbsDownSelected(show);

    if (show && scrollRef.current) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const generateBulletPoints = () => {
    const bulletPoints: string[] = [];

    if (needSignPosting) {
      bulletPoints.push(
        "Your current situation means you're looking for some help and support."
      );
      bulletPoints.push("You're not sure where you can get this support from.");
    }

    if (isImmediateNeed) {
      bulletPoints.push(
        "This is something requiring immediate attention – it can't wait."
      );
    } else {
      bulletPoints.push(
        "This doesn't need immediate attention, but you're likely to need it soon."
      );
    }

    if (isRetailPurchaseOffered) {
      bulletPoints.push("You need to make a one-off purchase.");
    }

    if (isLoanOffered) {
      bulletPoints.push(
        "You may need to borrow money to bridge a gap in your finances."
      );
    }

    return bulletPoints;
  };

  if (!userGoal) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex items-center justify-between pb-4 border-b">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 text-gray-600 hover:text-gray-900"
            aria-label="Back"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <h1 className="text-xl">Why we suggested these actions</h1>
        </div>
      </div>
      <main className="flex-1 container mx-auto pt-4">
        <div className="max-w-2xl mx-auto">
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">You told us</h3>
            <div className="bg-surface-light border-l-4 border-outline p-4">
              <p className="text-base mask-data">{userGoal.goal_statement}</p>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">
              From this, we understand that:
            </h3>
            <ul className="list-disc pl-6 space-y-2">
              {generateBulletPoints().map((point, index) => (
                <li key={index} className="text-base">
                  {point}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex items-center justify-center gap-4 mb-6">
            <span className="text-base">Was our understanding correct?</span>
            <button
              data-testid="thumbs-up"
              onClick={() => {
                setIsThumbsUpSelected(true);
                setIsThumbsDownSelected(false);
                setShowTextBox(false);
              }}
              className={`p-2 rounded-full transition-colors ${
                isThumbsUpSelected
                  ? "text-green-500"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                />
              </svg>
            </button>
            <button
              data-testid="thumbs-down"
              onClick={() => toggleTextBox(true)}
              className={`p-2 rounded-full transition-colors ${
                isThumbsDownSelected
                  ? "text-red-500"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018c.163 0 .326.02.485.06L17 4m-7 10v2a2 2 0 002 2h.095c.5 0 .905-.405.905-.905 0-.714.211-1.412.608-2.006L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
                />
              </svg>
            </button>
          </div>

          {showTextBox && (
            <div ref={scrollRef} className="mt-6">
              <p className="text-base mb-4">
                Can you try describing your financial problem or goal again?
              </p>
              <UserGoalInput
                onLoadingStateChanged={setIsLoading}
                initialPrompt={`Remember, the more specific and detailed you are, the better ${Resources.appName} may be able to help.`}
                isFeedback={true}
                parentGoalId={userGoal.user_goal_id}
              />
            </div>
          )}
        </div>
      </main>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <Loader
            message={`${Resources.appName} is working to solve your problem...`}
          />
        </div>
      )}

      <BottomNavBar />
    </div>
  );
};

export default ReviewActions;
