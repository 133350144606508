import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { Resources } from "../utils/resources";
import { getLoansPath } from "../utils/navigationUtils";
import { IntentSection, Intent } from "../utils/types/intent";
import Section from "../components/Section";
import BenefitsBuilder from "../components/BenefitsBuilder";
import ArticleList from "../components/ArticleList";
import Signposting from "../components/Signposting";
import { UserGoal } from "../utils/types";
import { TextButton } from "../components/TextButton";
import { log } from "../utils/uiUtils";
import { UserGoalStatement } from "../components/UserGoalStatement";

type GoalAction = UserGoal["goal_actions"][0];

const Recommendations: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userGoal, setDiscountsForGoal, setSaveOnShopping, benefitsSummary } =
    useUser();
  const { setIsVisible } = useBottomNavBar();
  const [primaryActions, setPrimaryActions] = useState<GoalAction[]>([]);
  const [secondaryActions, setSecondaryActions] = useState<GoalAction[]>([]);
  const [needSignPosting, setNeedSignPosting] = useState(false);
  const [activeIntent] = useState<Intent | undefined>(
    (location.state as { activeIntent?: Intent })?.activeIntent
  );

  useEffect(() => {
    setIsVisible(true); // Show bottom nav bar when component mounts
    return () => setIsVisible(false); // Hide bottom nav bar when component unmounts
  }, [setIsVisible]);

  useEffect(() => {
    if (!userGoal) {
      throw new Error("User goal is missing");
    }

    setPrimaryActions(
      userGoal.goal_actions.filter((action) => action.is_primary)
    );
    setSecondaryActions(
      userGoal.goal_actions.filter((action) => !action.is_primary)
    );
    setNeedSignPosting(userGoal.response.risk_score >= Resources.riskThreshold);
  }, [userGoal]);

  const handleRetailDiscountsAction = () => {
    if (userGoal) {
      setDiscountsForGoal(
        userGoal.user_goal_id,
        userGoal.response.purchase_category
      );
      navigate("/discounts");
    }
  };

  const handleLoanAction = () => {
    navigate(getLoansPath());
  };

  const handleSavingsAccountAction = () => {
    log.debug("Savings action's yet to be implemented");
  };

  const handleSaveOnShoppingAction = () => {
    setSaveOnShopping();
    navigate("/discounts");
  };

  const handleGovernmentGrantAction = () => {
    window.open(Resources.govtGrantsUrl, "_blank");
  };

  const buildSections = (actions: GoalAction[], isPrimary: boolean) => {
    return actions.map((action) => {
      const sectionName =
        action.goal_action as keyof typeof Resources.sectionConfigurations;
      const isDone = action.is_done;
      const section = Resources.sectionConfigurations[sectionName];

      // Check if this section corresponds to the active intent
      const isIntentSection =
        activeIntent && IntentSection[activeIntent] === sectionName;

      // For ds intent: Check if retail discounts exists in any action (primary or secondary)
      const hasRetailDiscounts = [...primaryActions, ...secondaryActions].some(
        (a) => a.goal_action === "retailDiscounts"
      );

      // For ds intent: Determine if we should fallback to saveOnShopping
      const shouldFallbackToSaveOnShopping =
        activeIntent === ("ds" as Intent) && !hasRetailDiscounts;

      // Highlight section if:
      // 1. It's the intent section (except for ds which needs special handling), or
      // 2. For ds intent: highlight retail discounts if present, or saveOnShopping if not
      const shouldHighlight =
        (isIntentSection && activeIntent !== ("ds" as Intent)) ||
        (activeIntent === ("ds" as Intent) &&
          ((hasRetailDiscounts && sectionName === "retailDiscounts") ||
            (shouldFallbackToSaveOnShopping &&
              sectionName === "saveOnShopping")));

      const handleAction = () => {
        switch (sectionName) {
          case "retailDiscounts":
            handleRetailDiscountsAction();
            break;
          case "loan":
            handleLoanAction();
            break;
          case "savingsAccount":
            handleSavingsAccountAction();
            break;
          case "saveOnShopping":
            handleSaveOnShoppingAction();
            break;
          case "grants":
            handleGovernmentGrantAction();
            break;
        }
      };

      return (
        <div
          key={action.goal_action_id}
          className={`${shouldHighlight ? "bg-orange-50 rounded-lg p-2" : ""}`}
          data-section={sectionName}
          data-is-intent={isIntentSection}
        >
          <Section
            isDone={isDone}
            sectionHeader={section.sectionHeader}
            sectionText={
              sectionName === "checkBenefits" ? (
                <BenefitsBuilder benefitsSummary={benefitsSummary} />
              ) : (
                section.sectionText
              )
            }
            ctaText={
              sectionName === "checkBenefits" ? undefined : section.ctaText
            }
            subText={"subText" in section ? section.subText : undefined}
            onPressed={handleAction}
          />
        </div>
      );
    });
  };

  if (!userGoal) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white pb-20">
      <main className="flex-1 max-w-3xl mx-auto w-full">
        {userGoal?.goal_statement && (
          <div>
            <p className="text-xl font-semibold mb-2">You told us:</p>
            <div className="mb-4">
              <UserGoalStatement
                goalStatement={userGoal.goal_statement}
                goalActions={userGoal.goal_actions.map((action) => ({
                  goalAction: action.goal_action,
                }))}
                timeframe={userGoal.response.timeframe}
                riskScore={userGoal.response.risk_score}
                parentGoalId={userGoal.user_goal_id}
                showFeedbackInput={true}
              />
            </div>
          </div>
        )}

        {needSignPosting && userGoal.response.risk_category && (
          <div className="mb-5">
            <Signposting category={userGoal.response.risk_category} />
          </div>
        )}

        <div className="mb-4">
          <p className="text-xl font-semibold">
            {userGoal.response.risk_score > Resources.riskThreshold &&
            userGoal.response.risk_category
              ? "Here are some other ways that Afforda may be able to help"
              : Resources.immediateConfig.helpText}
            {userGoal.response.risk_score <= Resources.riskThreshold && (
              <TextButton
                text="(explain why)"
                onClick={() => navigate("/review-actions")}
                className="ml-2 text-sm text-blue-500 underline"
              />
            )}
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-5xl mx-auto">
          {buildSections(primaryActions, true)}
        </div>

        {secondaryActions.length > 0 && (
          <>
            <h2 className="text-xl font-semibold mt-6 mb-3">
              {Resources.immediateConfig.improvementTitle}
            </h2>
            <p className="mb-4">{Resources.immediateConfig.improvementText}</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-5xl mx-auto">
              {buildSections(secondaryActions, false)}
            </div>
          </>
        )}

        {/* Only render ArticleList once userGoal is fully loaded */}
        {userGoal?.goal_statement && (
          <div className="mt-6">
            <ArticleList userGoalStatement={userGoal.goal_statement} />
          </div>
        )}
      </main>
    </div>
  );
};

export default Recommendations;
