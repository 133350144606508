import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useRoutes,
  RouteObject,
  Navigate,
  useLocation,
} from "react-router-dom";
import { URL_PATHS } from "./utils/routeUtils";
import { GoogleOAuthProvider } from "@react-oauth/google";
import routes from "./routes";
import { AuthProvider, useAuth } from "./utils/auth";
import { UserProvider } from "./contexts/UserContext";
import { FormProvider } from "./contexts/FormContext";
import { BottomNavBarProvider } from "./contexts/BottomNavBarContext";
import { SnackbarProvider } from "./components/SnackbarProvider";
import { useInitSnackbar } from "./hooks/useInitSnackbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BottomNavBar from "./components/BottomNavBar";
import config from "./configs/config";
import { UIUtils } from "./utils/uiUtils";
import useSourceTracking from "./hooks/useSourceTracking";
import useMediaQuery from "./hooks/useMediaQuery";

const AppRoutes: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  // Process routes to add authentication check
  const processedRoutes: RouteObject[] = routes.map((route) => {
    // If route is protected and user is not logged in
    if (route.protected && !user) {
      return {
        ...route,
        element: <Navigate to="/" state={{ from: location }} replace />,
      };
    }

    // If we're logged in and trying to access login/signup pages
    if (user && (currentPath === "/login" || currentPath === "/")) {
      // Check for redirectTo query parameter
      const params = new URLSearchParams(location.search);
      const redirectTo = params.get("redirectTo");

      // Find if redirectTo path exists in our routes and is a valid protected route
      const targetRoute = routes.find((r) => r.path === redirectTo);
      const isValidRedirect =
        targetRoute &&
        (targetRoute.protected || targetRoute.path === "/customer-home");

      return {
        ...route,
        element: (
          <Navigate
            to={isValidRedirect ? redirectTo! : "/customer-home"}
            replace
          />
        ),
      };
    }

    return route;
  });

  const element = useRoutes(processedRoutes);
  useInitSnackbar();

  const isMobile = useMediaQuery("(max-width: 640px)"); // This is the smallest breakpoint used in Tailwind
  // Define pages where the Header should not be shown on mobile
  const noHeaderPages = ["/"];
  const shouldShowHeader = !(
    isMobile && noHeaderPages.includes(location.pathname)
  );
  
  // Check if current page is ExtLanding
  const shouldShowFooter = location.pathname.startsWith(URL_PATHS.EXT_LANDING);

  return (
    <div className="flex flex-col">
      {shouldShowHeader && <Header />}
      <main>
        <div className="container-content pt-0 pb-[72px]">{element}</div>
      </main>
      {shouldShowFooter && <Footer/>}
      <BottomNavBar />
    </div>
  );
};

// Function to track clicks
const trackLinkClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (target.tagName === "A") {
    const anchor = target as HTMLAnchorElement;
    const trackValue =
      anchor.getAttribute("data-track") || anchor.textContent || "No text";
    UIUtils.trackExternalLinkRedirects(trackValue);
  }
};

// Function to track blur events
const trackFormElementBlur = (event: FocusEvent) => {
  const target = event.target as
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement;
  const ignoreTracking = target.getAttribute("data-ignore") === "true";
  const elementName = target.name || target.id || "Unnamed Element";
  let elementValue: string | boolean = target.value;
  const isSensitive = target.getAttribute("data-sensitive") === "true";

  // Handle checkboxes separately
  if (target.type === "checkbox") {
    elementValue = (target as HTMLInputElement).checked.toString();
  }
  if (!ignoreTracking) {
    UIUtils.trackInput(elementName, elementValue, isSensitive);
  }
};

const App: React.FC = () => {
  useSourceTracking();
  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("click", trackLinkClick);
    // Add event listener for blur events
    const formElements = document.querySelectorAll<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >("input, select, textarea");
    formElements.forEach((element) => {
      element.addEventListener("blur", trackFormElementBlur as EventListener);
    });

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", trackLinkClick);
      formElements.forEach((element) => {
        element.removeEventListener(
          "blur",
          trackFormElementBlur as EventListener
        );
      });
    };
  }, []);
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <UserProvider>
          <FormProvider>
            <BottomNavBarProvider>
              <SnackbarProvider>
                <Router>
                  <AppRoutes />
                </Router>
              </SnackbarProvider>
            </BottomNavBarProvider>
          </FormProvider>
        </UserProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
