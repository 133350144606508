import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import api from './api';
import { UIUtils } from './uiUtils';
import { deleteCookie, setCookie, getAffordaDomain } from './cookieUtils';

interface AuthContextType {
  user: { accessToken: string } | null;
  setUser: (user: { accessToken: string } | null) => void;
  loginWithGoogle: (credential: string) => Promise<any>;
  loginWithApple: (credential: string) => Promise<any>;
  signOut: () => void;
}

const TOKEN_STORAGE_KEY = 'afforda_auth_token';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Utility function to check if a JWT token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const [, payload] = token.split('.');
    const decodedPayload = JSON.parse(atob(payload));
    const expirationTime = decodedPayload.exp * 1000; // Convert to milliseconds
    return Date.now() >= expirationTime;
  } catch (error) {
    UIUtils.trackException('Token expiration check', error);
    return true; // Treat malformed tokens as expired
  }
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Initialize state from token only
  const [user, setUser] = useState<{ accessToken: string } | null>(() => {
    try {
      const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
      if (storedToken) {
        // Check if token is expired before initializing
        if (!isTokenExpired(storedToken)) {
          api.setToken(storedToken);
          return { accessToken: storedToken };
        } else {
          // Clear expired token
          localStorage.removeItem(TOKEN_STORAGE_KEY);
          // Clear cookie with correct domain
          deleteCookie("aat", getAffordaDomain());
          api.clearToken();
        }
      }
      return null;
    } catch (error) {
      UIUtils.trackException('Read token from localStorage', error);
      return null;
    }
  });

  // Set up periodic token expiration check
  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      if (token && isTokenExpired(token)) {
        // Token is expired, clear auth state
        setUser(null);
        api.clearToken();
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem('afforda_user_data');
        // Clear cookie with correct domain
        deleteCookie("aat", getAffordaDomain());
        sessionStorage.clear();
        // Redirect to login
        window.location.href = '/login';
      }
    };

    // Check every minute
    const interval = setInterval(checkTokenExpiration, 60000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  const loginWithGoogle = async (credential: string) => {
    try {
      const response = await api.loginWithGoogle(credential);
      
      if (response.data.status === 'SUCCESS') {
        if (!response.data.access_token) {
          throw new Error('No access token received from server');
        }
        
        // Verify token is not expired before setting
        if (!isTokenExpired(response.data.access_token)) {
          // Store token and update state
          localStorage.setItem(TOKEN_STORAGE_KEY, response.data.access_token);
          // Set session cookie with correct domain
          setCookie("aat", response.data.access_token, {
            sessionOnly: true,
            domain: getAffordaDomain()
          });
          api.setToken(response.data.access_token);
          setUser({ accessToken: response.data.access_token });
        } else {
          throw new Error('Received expired token from server');
        }
      }
      
      return response;
    } catch (error) {
      throw error;
    }
  };

  const loginWithApple = async (credential: string) => {
    try {
      const response = await api.loginWithApple(credential);
      
      if (response.data.status === 'SUCCESS') {
        if (!response.data.access_token) {
          throw new Error('No access token received from server');
        }
        
        // Verify token is not expired before setting
        if (!isTokenExpired(response.data.access_token)) {
          // Store token and update state
          localStorage.setItem(TOKEN_STORAGE_KEY, response.data.access_token);
          // Set session cookie with correct domain
          setCookie("aat", response.data.access_token, {
            sessionOnly: true,
            domain: getAffordaDomain()
          });
          api.setToken(response.data.access_token);
          setUser({ accessToken: response.data.access_token });
        } else {
          throw new Error('Received expired token from server');
        }
      }
      
      return response;
    } catch (error) {
      throw error;
    }
  };

  const signOut = () => {
    // Clear user state
    setUser(null);
    
    // Clear API token
    api.clearToken();
    
    // Clear auth data from storage
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem('afforda_user_data');
    // Clear cookie with correct domain
    deleteCookie("aat", getAffordaDomain());
    sessionStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ user, setUser, loginWithGoogle, loginWithApple, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
