import React from "react";
import { useForm } from "../../contexts/FormContext";
import { MdWarning, MdInfo } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";

interface ExpenseFieldProps {
  label: string;
  name: string;
  tooltip: string;
  value: string;
  onChange: (value: string) => void;
  error: string | null;
  touched: boolean;
}

const ExpenseField: React.FC<ExpenseFieldProps> = ({
  label,
  name,
  tooltip,
  value,
  onChange,
  error,
  touched,
}) => (
  <div className="space-y-2">
    <div className="flex items-center space-x-2">
      <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
        {label}
      </label>
      <Tooltip 
        title={tooltip} 
        arrow 
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        onTouchStart={(e) => e.preventDefault()}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -13],
                },
              },
            ],
          },
        }}
      >
        <div className="cursor-pointer p-1 -m-1">
          <MdInfo className="h-5 w-5 text-gray-400" />
        </div>
      </Tooltip>
    </div>
    <div className="relative mt-1">
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
        £
      </span>
      <input
        id={name}
        type="number"
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`block w-full pl-7 pr-12 py-2 border ${
          error && touched ? "border-red-500" : "border-gray-300"
        } rounded-md focus:ring-blue-500 focus:border-blue-500`}
        placeholder="0"
        aria-invalid={!!(error && touched)}
        aria-describedby={error && touched ? `${name}-error` : undefined}
      />
    </div>
    {error && touched && (
      <p
        className="mt-2 text-sm text-red-600"
        id={`${name}-error`}
        role="alert"
      >
        {error}
      </p>
    )}
  </div>
);

const Step7: React.FC = () => {
  const {
    formData,
    updateField,
    validateField,
    isFieldTouched,
    touchedFields,
  } = useForm();

  const expenseFields = [
    {
      label: "Your share of rent/mortgage",
      name: "rent",
      tooltip: "Your contribution to the rent and/or mortgage",
    },
    {
      label: "Monthly credit commitments",
      name: "credit_commitments",
      tooltip:
        "Payments for: payday loans, other loans, lines of credit, credit cards, storecards, home credit, catalogues, retail finance, car finance, insurances, etc.",
    },
    {
      label: "Your share of utility bills (Including council tax)",
      name: "utility_bills",
      tooltip:
        "Your contribution to gas, electricity, water and other energy bills, along with council tax.",
    },
    {
      label: "Transport",
      name: "transport",
      tooltip: "Public transport, car/van costs, fuel, servicing, etc.",
    },
    {
      label: "Food and drink",
      name: "food_drink",
      tooltip:
        "Food and drink at home or elsewhere (including restaurants, pubs, etc).",
    },
    {
      label: "Other Expenses",
      name: "other_expenses",
      tooltip:
        "Leisure/recreation (including streaming services), medical expenses, education (including school trips and other ad hoc costs), reading materials, holidays, clothing and personal care (toiletries, beauty products, hairdressing, etc).",
    },
  ];

  // Get validation errors for each field
  const getFieldError = (fieldName: string) =>
    isFieldTouched(fieldName) || touchedFields[fieldName]
      ? validateField(fieldName, formData[fieldName] || "")
      : null;

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">What are your monthly expenses?</h1>
      </div>

      {/* Alert Box */}
      <div className="bg-blue-50 rounded-lg p-4 flex items-center space-x-3">
        <MdWarning className="h-6 w-6 text-blue-500" />
        <p className="text-gray-800">
          We use this to make sure you can afford your repayments.
        </p>
      </div>

      {/* Expense Fields */}
      {expenseFields.map((field) => (
        <ExpenseField
          key={field.name}
          label={field.label}
          name={field.name}
          tooltip={field.tooltip}
          value={formData[field.name] || ""}
          onChange={(value) => updateField(field.name, value)}
          error={getFieldError(field.name)}
          touched={isFieldTouched(field.name) || !!touchedFields[field.name]}
        />
      ))}
    </div>
  );
};

export default Step7;
