import Cookies from 'js-cookie';
import { Resources, LsrcCode } from './resources';

// Utility function to normalize Google Ads source values
export const groupGAdsSource = (source?: string): string => {
  if (!source) return 'default';
  if (source.endsWith('_gads')) {
    return 'google_ads';
  }
  return source;
};

// Helper function to check if a string matches any pattern in a list
const checkStringInList = (str: string, patternList: readonly string[]): boolean => {
  if (!str || !patternList || patternList.length === 0) return false;
  return patternList.some(pattern => {
    const regex = new RegExp(pattern, 'i');
    return regex.test(str);
  });
};

// Function to get priority for a leadsource
const getLsrcPriority = (lsrc: string): number => {
  if (!lsrc) return 8; // Default priority
  
  for (const [code, priority] of Object.entries(Resources.lsrc_priority) as [LsrcCode, number][]) {
    const inclusionList = Resources.ls_inclusion[code];
    const exclusionList = Resources.ls_exclusion[code];
    
    if (checkStringInList(lsrc, inclusionList) && !checkStringInList(lsrc, exclusionList)) {
      return priority;
    }
  }
  
  return 8; // Default priority if no matches
};

// Function to get leadsource code from priority
const getLsrcCode = (priority: number): string => {
  return Object.keys(Resources.lsrc_priority).find(key => Resources.lsrc_priority[key as LsrcCode] === priority) as LsrcCode || 'LSD';
};

// Utility function to fetch query parameters
const fetchQueryParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

// Function to compute Ads Source Lsrc
const computeAdsSourceLsrc = (): string => {
  let adsLsrc = '';
  const gadSource = fetchQueryParam('gad_source');
  const gbraid = fetchQueryParam('gbraid');
  const utmQuery = [gadSource, gbraid];
  const nonEmptyValues = utmQuery.filter(value => value !== null);
  if (nonEmptyValues.length > 0) {
    adsLsrc = nonEmptyValues.join('_') + '_gads';
  }
  return adsLsrc;
};

// Function to compute UTM Source Lsrc
const computeUtmSourceLsrc = (): string => {
  let utmLsrc = '';
  const utmSource = fetchQueryParam('utm_source');
  const utmMedium = fetchQueryParam('utm_medium');
  const utmCampaign = fetchQueryParam('utm_campaign');
  const utmContent = fetchQueryParam('utm_content');
  const utmTerm = fetchQueryParam('utm_term');
  const utmQuery = [utmSource, utmMedium, utmCampaign, utmContent, utmTerm];
  const nonEmptyValues = utmQuery.filter(value => value !== null);
  if (nonEmptyValues.length > 0) {
    utmLsrc = nonEmptyValues.join('_') + '_utm';
  }
  return utmLsrc;
};

// Function to compute Referrer Source Lsrc
const computeReferrerSourceLsrc = (): string => {
  const referrer = document.referrer;
  const hostname = window.location.hostname;
  let sourceLsrc = '';
  if (referrer && !referrer.includes(hostname)) {
    sourceLsrc = 'afforda_organic';
  } else if (!referrer) {
    sourceLsrc = 'afforda_direct';
  }
  return sourceLsrc;
};

// Main function to set Lsrc Cookies
export const calculateLeadSource = (): void => {
  let lsrcCurrent = computeAdsSourceLsrc();
  if (!lsrcCurrent) {
    lsrcCurrent = computeUtmSourceLsrc();
  }
  if (!lsrcCurrent) {
    lsrcCurrent = computeReferrerSourceLsrc();
  }
  
  if (lsrcCurrent) {
    const current_priority = getLsrcPriority(lsrcCurrent);
    const current_code = getLsrcCode(current_priority);
    const lsrc_detail = `priority=${current_priority}|code=${current_code}`;
    
    const existing_lsrc = Cookies.get('lsrc');
    const existing_detail = Cookies.get('lsrc_detail');
    let existing_priority = 8;
    
    if (existing_detail) {
      const priority_match = existing_detail.match(/priority=(\d+)/);
      if (priority_match) {
        existing_priority = parseInt(priority_match[1]);
      }
    }

    // Only set cookies if no existing source or current priority is higher/equal
    if (!existing_lsrc || current_priority <= existing_priority) {
      let hostname = window.location.hostname;
      if (hostname.includes('afforda.co.uk')) {
        hostname = '.afforda.co.uk';
      }
      
      Cookies.set('lsrc', lsrcCurrent, { expires: 60, domain: hostname });
      Cookies.set('lsrc_detail', lsrc_detail, { expires: 60, domain: hostname });
      
      const datetime = new Date().toISOString();
      const lsrc_history = Cookies.get('lsrc_history') || '';
      Cookies.set('lsrc_history', 
        lsrc_history ? `${lsrc_history}|${lsrcCurrent}:${datetime}` : `${lsrcCurrent}:${datetime}`, 
        { expires: 60, domain: hostname }
      );
    }
  }
};
