import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../configs/config";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const [expandedSections, setExpandedSections] = useState<string[]>([]);

  const toggleSection = (section: string) => {
    setExpandedSections((prev) =>
      prev.includes(section)
        ? prev.filter((s) => s !== section)
        : [...prev, section]
    );
  };

  const isSectionExpanded = (section: string) => {
    return expandedSections.includes(section);
  };

  return (
    <footer className="bg-[#244355] text-white">
      <div className="container mx-auto px-4 py-8">
        {/* Top section with logo and navigation */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {/* Logo Column */}
          <div>
            <img
              src={`${config.WORDPRESS_URL}/wp-content/uploads/2024/12/logo-orange-a-white.svg`}
              alt="Afforda Logo"
              className="w-48"
            />
          </div>

          {/* Legal Column - Desktop View */}
          <div className="hidden md:block" data-testid="desktop-nav">
            <h3 className="text-xl font-semibold mb-4">Legal</h3>
            <nav className="flex flex-col space-y-2">
              <a
                href={`${config.WORDPRESS_URL}/privacy-policy/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ccd0d1] hover:text-white"
              >
                Privacy policy
              </a>
              <a
                href={`${config.WORDPRESS_URL}/cookie-policy/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ccd0d1] hover:text-white"
              >
                Cookie policy
              </a>
              <a
                href={`${config.WORDPRESS_URL}/terms-of-use/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ccd0d1] hover:text-white"
              >
                Terms of use
              </a>
              <a
                href={`${config.WORDPRESS_URL}/consent/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ccd0d1] hover:text-white"
              >
                Marketing consent
              </a>
            </nav>
          </div>

          {/* Help Column - Desktop View */}
          <div className="hidden md:block" data-testid="desktop-nav">
            <h3 className="text-xl font-semibold mb-4">Help</h3>
            <nav className="flex flex-col space-y-2">
              <a
                href={`${config.WORDPRESS_URL}/faqs/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ccd0d1] hover:text-white"
              >
                FAQs
              </a>
              <a
                href={`${config.WORDPRESS_URL}/resources/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ccd0d1] hover:text-white"
              >
                Resources
              </a>
              <a
                href="/contact-us"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contact-us");
                }}
                className="text-[#ccd0d1] hover:text-white"
              >
                Contact us
              </a>
              <a
                href="/contact-us?type=complaint"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contact-us?type=complaint");
                }}
                className="text-[#ccd0d1] hover:text-white"
              >
                Make a complaint
              </a>
            </nav>
          </div>
        </div>

        {/* Mobile Navigation Dropdowns */}
        <div className="md:hidden mb-8" data-testid="mobile-nav">
          {/* Legal Dropdown */}
          <div className="border-t border-[#ffffff1f]">
            <button
              className="w-full py-4 flex justify-between items-center"
              onClick={() => toggleSection("legal")}
              aria-expanded={isSectionExpanded("legal")}
            >
              <span className="text-xl font-semibold">Legal</span>
              <span className="text-2xl">
                {isSectionExpanded("legal") ? "-" : "+"}
              </span>
            </button>

            <div
              data-testid="legal-content"
              className={`overflow-hidden transition-all duration-300 ${
                isSectionExpanded("legal")
                  ? "max-h-48 opacity-100 mb-4"
                  : "max-h-0 opacity-0"
              }`}
            >
              <nav className="flex flex-col space-y-2 py-2">
                <a
                  href={`${config.WORDPRESS_URL}/privacy-policy/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Privacy policy
                </a>
                <a
                  href={`${config.WORDPRESS_URL}/cookie-policy/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Cookie policy
                </a>
                <a
                  href={`${config.WORDPRESS_URL}/terms-of-use/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Terms of use
                </a>
                <a
                  href={`${config.WORDPRESS_URL}/consent/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Marketing consent
                </a>
              </nav>
            </div>
          </div>

          {/* Help Dropdown */}
          <div className="border-t border-[#ffffff1f]">
            <button
              className="w-full py-4 flex justify-between items-center"
              onClick={() => toggleSection("help")}
              aria-expanded={isSectionExpanded("help")}
            >
              <span className="text-xl font-semibold">Help</span>
              <span className="text-2xl">
                {isSectionExpanded("help") ? "-" : "+"}
              </span>
            </button>

            <div
              data-testid="help-content"
              className={`overflow-hidden transition-all duration-300 ${
                isSectionExpanded("help")
                  ? "max-h-48 opacity-100 mb-4"
                  : "max-h-0 opacity-0"
              }`}
            >
              <nav className="flex flex-col space-y-2 py-2">
                <a
                  href={`${config.WORDPRESS_URL}/faqs/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#ccd0d1] hover:text-white"
                >
                  FAQs
                </a>
                <a
                  href={`${config.WORDPRESS_URL}/resources/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Resources
                </a>
                <a
                  href="/contact-us"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/contact-us");
                  }}
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Contact us
                </a>
                <a
                  href="/contact-us?type=complaint"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/contact-us?type=complaint");
                  }}
                  className="text-[#ccd0d1] hover:text-white"
                >
                  Make a complaint
                </a>
              </nav>
            </div>
          </div>

          <div className="border-t border-[#ffffff1f]"></div>
        </div>

        <p className="text-[#ccd0d1] mb-4 md:mb-0">
          © 2024 Afforda. All Rights Reserved.
        </p>
        {/* Separator */}
        <hr className="border-[#ffffff1f] mb-6" />

        {/* Copyright and Social Links */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="flex space-x-4">
            <a
              href="https://www.youtube.com/@Afforda"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <div className="bg-white p-2 rounded-md">
                <svg
                  className="w-6 h-6 text-[#031b1d]"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,15.464V8.536L16,12L10,15.464z" />
                </svg>
              </div>
            </a>
            <a
              href="https://x.com/afforda_uk"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <div className="bg-white p-2 rounded-md">
                <svg
                  className="w-6 h-6 text-[#031b1d]"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M22,5.8a8.6,8.6,0,0,1-2.36.65,4.07,4.07,0,0,0,1.8-2.27,8.1,8.1,0,0,1-2.6,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07,4.09,4.09,0,0,0,1.82,3.41,4.05,4.05,0,0,1-1.86-.51v.05a4.1,4.1,0,0,0,3.3,4,3.93,3.93,0,0,1-1.85.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" />
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/afforda.UK"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <div className="bg-white p-2 rounded-md">
                <svg
                  className="w-6 h-6 text-[#031b1d]"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z" />
                </svg>
              </div>
            </a>
          </div>
        </div>

        {/* Separator */}
        <hr className="border-[#ffffff1f] mb-6" />
        {/* Important Information */}
        <div>
          <h3 className="text-xl font-semibold mb-4">Important information</h3>
          <p className="text-[#ccd0d1] mb-4">
            Afforda is a trading name of GAIN Credit LLC. Afforda is acting as a
            credit broker and not a lender. Registered Address: 251 Little Falls
            Drive, Wilmington, DE 19808, USA. Registration Number: 4124111.
            Authorised and Regulated by the Financial Conduct Authority.
            Registration Number 689378. Licensed by the Information
            Commissioners Office, (registration number Z2752028). Contact
            Address: Afforda, PO Box 10756, LEICESTER, LE3 4GX
          </p>
          <p className="text-[#ccd0d1] mt-4">
            We are a credit broker and may pay commissions to credit brokers or
            other intermediaries who introduce customers to us. The amount of
            commission we pay can vary depending on the broker, the product
            offered, and the terms agreed with the broker. This commission is
            not charged to you and does not affect the total cost of your loan.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
