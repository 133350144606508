import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import api, { fetchLeadLandingData, submitLeadLanding } from '../utils/api';
import { UIUtils } from '../utils/uiUtils';
import { Button } from '../components/Button';
import Loader from '../components/Loader';
import { usePostLoginProcessor } from '../utils/PostLoginProcessor';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';

interface LeadLandingData {
  eligibility: boolean;
  first_name: string;
  is_verified: boolean;
  email: string;
  access_token: string;
}

const LeadLanding: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<LeadLandingData | null>(null);
  const [loanPurpose, setLoanPurpose] = useState("");
  const navigate = useNavigate();
  const [goalError, setGoalError] = useState("");
  const [emailError, setEmailError] = useState("");
  const { processPostLogin } = usePostLoginProcessor();
  const [email, setEmail] = useState("");
  const rtj = searchParams.get("rtj");
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(false);
    return () => setIsVisible(true);
  }, [setIsVisible]);

  useEffect(() => {
    const fetchData = async () => {
      if (!rtj) {
        UIUtils.showMessageOnSnackBar("Invalid URL");
        setLoading(false);
        return;
      }

      try {
        const response = await fetchLeadLandingData(rtj);
        setData(response.data);
        setEmail(response.data.email);
        // Redirect if not eligible
        if (!response.data.eligibility) {
          UIUtils.trackNREvents(
            "lead_landing_ineligible",
            "Link expired, redirecting to get-started"
          );
          navigate("/");
          return;
        }
      } catch (err) {
        UIUtils.showMessageOnSnackBar(
          "Something went wrong. Please try again later."
        );
        UIUtils.trackException("Lead landing fetch error", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rtj, navigate]);

  const validateForm = (): boolean => {
    let errors = 0;
    if (!loanPurpose) {
      setGoalError("Please tell us why you are looking for a loan");
      errors += 1;
    }
    if (!data?.is_verified && !email) {
      setEmailError("Please enter a valid email");
      errors += 1;
    }
    return errors === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data) {
      if (!validateForm()) {
        UIUtils.trackFormSubmit("0", "error");
        return;
      }
      UIUtils.trackFormSubmit("0", "success");
      setSubmitting(true);
      try {
        let request;
        if (data?.is_verified) {
          request = {
            intent: loanPurpose,
          };
        } else {
          request = {
            intent: loanPurpose,
            confirmed_email: email,
          };
        }
        const response = await submitLeadLanding(data.access_token, request);
        const { status } = response.data;

        UIUtils.trackNREvents("lead_landing_submit", status);

        if (status === "VERIFIED") {
          UIUtils.trackNREvents(
            "lead_landing_vsuccess",
            "Redirecting verified user to afforda app page"
          );
          const accessToken = response.data.access_token;
          api.setToken(accessToken);
          const userData = await api.fetchUserData();

          // Process post login with the fetched user data
          const { redirectTo } = processPostLogin(
            userData.data,
            accessToken,
            "lead_id"
          );

          navigate(redirectTo, { replace: true });
        } else if (status === "PENDING_VERIFICATION") {
          UIUtils.trackNREvents(
            "lead_landing_success",
            "Redirecting user to email verification"
          );
          const refid = response.data.reference_id;
          navigate(`/email-verification/${refid}`);
        } else if (status === "EXPIRED") {
          UIUtils.trackNREvents("lead_landing_existing_user", "Link expired");
          UIUtils.showMessageOnSnackBar(
            "It looks like this link has expired. If you're an existing user, please log in to continue. If you're new to Afforda, please register."
          );
        } else {
          UIUtils.trackNREvents(
            "lead_landing_error",
            "Error in lead landing submit"
          );
          UIUtils.showMessageOnSnackBar(
            "Something went wrong. Please try again after some time."
          );
        }
      } catch (err) {
        UIUtils.trackException("Lead landing submit error", err);
        UIUtils.showMessageOnSnackBar(
          "Something went wrong. Please try again after sometime."
        );
      } finally {
        setSubmitting(false);
      }
    }
  };

  if (loading) {
    return <Loader message="Loading..." />;
  }

  if (!data) return null;

  if (!data.eligibility) {
    return <Loader message="Redirecting..." />;
  }

  if (data.is_verified) {
    return (
      <div className="min-h-screen flex flex-col items-center">
        {/* Header Section */}
        <div className="flex items-center justify-between pb-4 border-b max-w-2xl w-full">
          <div className="flex items-center">
            <h1 className="text-xl">Welcome to Afforda</h1>
          </div>
        </div>

        {/* Content Section */}
        <div className="max-w-2xl w-full pt-6">
          <h2 className="text-lg font-semibold text-gray-800">
            It’s great to see you here{" "}
            <span className="font-bold">{data.first_name}</span>
          </h2>
          <p className="mt-2 mb-2 text-base text-gray-800">
            We understand you were looking for a loan. If you can tell us below
            about why you needed a loan, we can look for ways we may be able to
            help.
          </p>
          <form onSubmit={handleSubmit} data-testid="lead-landing-form">
            {/* Textarea */}
            <textarea
              placeholder="E.g. My washing machine broke"
              className="w-full p-2 border rounded"
              rows={4}
              value={loanPurpose}
              onChange={(e) => setLoanPurpose(e.target.value)}
            />
            {goalError && (
              <p className="text-red-500 text-sm mt-1">{goalError}</p>
            )}

            {/* Email Note */}
            <p className="mt-4 mb-4 text-gray-800 text-base">
              You can login to Afforda anytime using your email address -{" "}
              <span className="font-medium text-gray-800 data-hj-suppress">
                {data.email}
              </span>
            </p>

            {/* Button */}
            <Button
              text="See my options"
              onClick={() => {}}
              type="submit"
              className="w-full"
              isLoading={submitting}
              loadingText="Checking for options..."
            />
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center">
      {/* Header Section */}
      <div className="flex items-center justify-between pb-4 border-b max-w-2xl w-full">
        <div className="flex items-center">
          <h1 className="text-xl">Welcome to Afforda</h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-2xl w-full pt-6">
        <h2 className="text-lg font-semibold text-gray-800">
          It’s great to see you here{" "}
          <span className="font-bold">{data.first_name}</span>
        </h2>
        <p className="mt-2 mb-2 text-base text-gray-800">
          We understand you were looking for a loan. If you can tell us below
          about why you needed a loan, we can look for ways we may be able to
          help.
        </p>

        {/* Textarea */}
        <form onSubmit={handleSubmit} data-testid="lead-landing-form">
          <textarea
            placeholder="E.g. My washing machine broke"
            className="w-full p-2 border rounded"
            rows={4}
            onChange={(e) => setLoanPurpose(e.target.value)}
          />
          {goalError && (
            <p className="text-red-500 text-sm mt-1">{goalError}</p>
          )}

          {/* Email Note */}
          <p className="mt-2 mb-2 text-base text-gray-800">
            We’ll be sending you a code to verify your email address, so please
            confirm that it is correct.
          </p>
          <div className="mb-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              data-sensitive="true"
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              placeholder="Email"
              aria-label="Email"
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">{emailError}</p>
            )}
          </div>

          {/* Button */}
          <Button
            text="Send code"
            onClick={() => {}}
            type="submit"
            className="w-full"
            isLoading={submitting}
            loadingText="Sending..."
          />
        </form>
      </div>
    </div>
  );
};

export default LeadLanding;
