import React, { useState, useRef, useEffect } from "react";
import {
  FiThumbsUp,
  FiThumbsDown,
  FiChevronUp,
  FiChevronDown,
} from "react-icons/fi";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import UserGoalInput from "./UserGoalInput";
import { Resources } from "../utils/resources";
import SmallLoader from "./SmallLoader";
import { Button } from "./Button";
import { useUser } from "../contexts/UserContext";
import { processUserGoal } from "../utils/api";

interface UserGoalStatementProps {
  goalStatement: string;
  goalActions?: Array<{ goalAction: string }>;
  timeframe?: string;
  riskScore?: number;
  onFeedbackSubmit?: (feedback: string) => void;
  parentGoalId?: number;
  showFeedbackInput?: boolean;
}

export const UserGoalStatement: React.FC<UserGoalStatementProps> = ({
  goalStatement,
  goalActions = [],
  timeframe = "",
  riskScore = 0,
  onFeedbackSubmit,
  parentGoalId,
  showFeedbackInput = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isThumbsUpSelected, setIsThumbsUpSelected] = useState(false);
  const [isThumbsDownSelected, setIsThumbsDownSelected] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [charCount, setCharCount] = useState(0);
  const MAX_CHARS = 250;
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const generateBulletPoints = () => {
    const points = [];

    // Check if user needs signposting (riskScore >= threshold)
    if (riskScore >= Resources.riskThreshold) {
      points.push(
        "Your current situation means you're looking for some help and support."
      );
      points.push("You're not sure where you can get this support from.");
    }

    // Check timeframe
    if (timeframe === "immediate") {
      points.push(
        "This is something requiring immediate attention – it can't wait."
      );
    } else {
      points.push(
        "This doesn't need immediate attention, but you're likely to need it soon."
      );
    }

    // Check retail purchase
    if (goalActions.some((action) => action.goalAction === "retailDiscounts")) {
      points.push("You need to make a one-off purchase.");
    }

    // Check loan requirement
    if (goalActions.some((action) => action.goalAction === "loan")) {
      points.push(
        "You may need to borrow money to bridge a gap in your finances."
      );
    }

    return points;
  };

  const handleThumbsUp = () => {
    setIsThumbsUpSelected(true);
    setIsThumbsDownSelected(false);
    setShowFeedback(false);
    // Add a brief delay before collapsing
    setTimeout(() => {
      setIsExpanded(false);
    }, 500);
  };

  const handleThumbsDown = () => {
    setIsThumbsDownSelected(true);
    setIsThumbsUpSelected(false);
    setShowFeedback(true);

    // Scroll to top of component after it renders
    setTimeout(() => {
      componentRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  useEffect(() => {
    setCharCount(feedback.length);
  }, [feedback]);

  const {
    updateUserData,
    user,
    benefitsSummary,
    benefitsBreakdown,
    marketing_email_dnc_flag,
  } = useUser();

  const handleFeedbackSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await processUserGoal(feedback, parentGoalId);

      // Update user data while preserving other context
      updateUserData({
        userGoal: response.data,
        user,
        benefitsSummary,
        benefitsBreakdown,
        marketing_email_dnc_flag,
      });

      setShowFeedback(false);
      setFeedback("");

      // Call the callback if provided
      if (onFeedbackSubmit) {
        onFeedbackSubmit(feedback);
      }
    } catch (error) {
      console.error("Error processing goal:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      ref={componentRef}
      className="bg-blue-50 border border-l-8 border-blue-500 shadow-lg p-4 space-y-4 relative"
    >
      {isLoading && (
        <div className="absolute inset-0 bg-blue-50 flex items-center justify-center z-10">
          <SmallLoader
            message="Processing your goal..."
            className="flex flex-col items-center justify-center"
            spinnerClassName="h-8 w-8 rounded-full border-t-2 border-b-2 border-blue-500 animate-spin mb-2"
            messageClassName="text-blue-700 text-sm"
          />
        </div>
      )}
      <p className="mb-4 mask-data">{goalStatement}</p>

      <div className="border-t border-blue-200 pt-2">
        <button
          className="w-full flex justify-between items-center cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-label="our understanding"
          aria-expanded={isExpanded}
        >
          <h4 className="font-medium text-sm text-blue-700">
            Our understanding:
          </h4>
          <span className="ml-4 p-1 hover:bg-blue-100 rounded-full transition-colors">
            {isExpanded ? (
              <FiChevronUp className="h-5 w-5 text-blue-700" />
            ) : (
              <FiChevronDown className="h-5 w-5 text-blue-700" />
            )}
          </span>
        </button>

        {isExpanded && (
          <div className="overflow-hidden transition-all duration-300 ease-in-out max-h-[1000px] opacity-100 mt-4">
            <div className="space-y-4">
              <ul className="space-y-2">
                {generateBulletPoints().map((point, index) => (
                  <li key={index} className="flex items-start">
                    <VscDebugBreakpointLog className="h-4 w-4 text-blue-700 mt-1 mr-2 flex-shrink-0" />
                    <span className="text-sm">{point}</span>
                  </li>
                ))}
              </ul>

              <div className="border-t border-blue-200 pt-4">
                <div className="flex items-center justify-center space-x-4">
                  <span className="text-sm text-blue-700">
                    Was our understanding correct?
                  </span>
                  <button
                    onClick={handleThumbsUp}
                    aria-label="thumbs up"
                    className={`p-1.5 rounded-full transition-colors ${
                      isThumbsUpSelected ? "bg-green-100" : "hover:bg-blue-100"
                    }`}
                  >
                    <FiThumbsUp
                      data-testid="thumbs-up-icon"
                      className={`h-5 w-5 ${
                        isThumbsUpSelected ? "text-green-600" : "text-blue-700"
                      }`}
                    />
                  </button>
                  <button
                    onClick={handleThumbsDown}
                    aria-label="thumbs down"
                    className={`p-1.5 rounded-full transition-colors ${
                      isThumbsDownSelected ? "bg-red-100" : "hover:bg-blue-100"
                    }`}
                  >
                    <FiThumbsDown
                      className={`h-5 w-5 ${
                        isThumbsDownSelected ? "text-red-600" : "text-blue-700"
                      }`}
                    />
                  </button>
                </div>
              </div>

              {showFeedback && (
                <div className="border-t border-blue-200 pt-4 pb-2 px-2 space-y-3">
                  {showFeedbackInput ? (
                    <div className="relative">
                      <div className="space-y-3">
                        <div className="relative">
                          <textarea
                            value={feedback}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue.length <= MAX_CHARS) {
                                setFeedback(newValue);
                              }
                            }}
                            placeholder="Can you try describing your financial problem or goal again?"
                            className={`text-sm w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                              charCount > MAX_CHARS
                                ? "border-red-500"
                                : "border-blue-200"
                            }`}
                            rows={3}
                          />
                          <div
                            className={`text-xs ${
                              charCount > MAX_CHARS
                                ? "text-red-500"
                                : "text-gray-500"
                            }`}
                          >
                            {MAX_CHARS - charCount} characters remaining
                          </div>
                        </div>
                        <Button
                          text="Submit"
                          onClick={handleFeedbackSubmit}
                          disabled={
                            charCount > MAX_CHARS ||
                            charCount === 0 ||
                            isLoading
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <UserGoalInput
                        onLoadingStateChanged={setIsLoading}
                        initialPrompt={`Remember, the more specific and detailed you are, the better ${Resources.appName} may be able to help.`}
                        isFeedback={true}
                        parentGoalId={parentGoalId}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
