import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { parse } from 'date-fns';
import { UIUtils } from '../utils/uiUtils';

// Custom input component with calendar icon
const CustomInput = React.forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement>>(
  ({ value, onClick, ...rest }, ref) => (
    <div className="relative">
      <input
        ref={ref}
        value={value}
        onClick={onClick}
        {...rest}
        className={`${rest.className} pl-3 pr-10`} // Adjust padding to accommodate icon
      />
      <div 
        className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
        aria-hidden="true"
      >
        <svg 
          className="w-5 h-5 text-gray-400" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
    </div>
  )
);

interface DateInputProps {
  dataSensitive: String | "false";
  selected: Date | null;
  onChange: (date: Date | null) => void;
  placeholderText?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  className?: string;
  label?: string;
  error?: string;
  showYearDropdown?: boolean;
  scrollableYearDropdown?: boolean;
  yearDropdownItemNumber?: number;
  name?: string;
  dateFormat?: string; // Optional format for display
}

const DateInput: React.FC<DateInputProps> = ({
  dataSensitive = 'false',
  selected,
  onChange,
  placeholderText = 'DD/MM/YYYY',
  minDate,
  maxDate,
  disabled = false,
  className = '',
  label,
  error,
  showYearDropdown = false,
  scrollableYearDropdown = false,
  yearDropdownItemNumber,
  name = '',
  dateFormat = 'dd/MM/yyyy' // Default to dd/MM/yyyy if not specified
}) => {
  const [selectedDate, setSelectedDate] = React.useState<string | null>(null);
  
  const handleChange = (date: Date | null) => {
    // UIUtils.trackInput(label, date?.toString() || "", dataSensitive === "true");
    onChange(date);
  };

  const handleBlur = () => {
    UIUtils.trackInput(label, selectedDate || "", dataSensitive === "true");
  }

  const handleManualInput = (e: any) => {
    if (!e || !e.target || typeof e.target.value !== 'string') {
      return;
    }

    const value = e.target.value;
    
    if (!value) {
      onChange(null);
      return;
    }
    setSelectedDate(value?.toString() || "");

    try {
      // Try different date formats
      const formats = [
        'dd/MM/yyyy',
        'dd-MM-yyyy',
        'yyyy-MM-dd'
      ];

      let parsedDate: Date | null = null;

      // Try each format until one works
      for (const format of formats) {
        try {
          const date = parse(value, format, new Date());
          if (!isNaN(date.getTime())) {
            parsedDate = date;
            break;
          }
        } catch (error) {
          continue;
        }
      }

      // Try partial input (dd/MM or dd-MM)
      if (!parsedDate && value.length === 5) {
        const currentYear = new Date().getFullYear();
        if (value.includes('/')) {
          parsedDate = parse(`${value}/${currentYear}`, 'dd/MM/yyyy', new Date());
        } else if (value.includes('-')) {
          parsedDate = parse(`${value}-${currentYear}`, 'dd-MM-yyyy', new Date());
        }
      }

      if (!parsedDate || isNaN(parsedDate.getTime())) {
        return;
      }

      // Check if date is within allowed range
      if (minDate && parsedDate < minDate) return;
      if (maxDate && parsedDate > maxDate) return;
      
      onChange(parsedDate);
    } catch (error) {
      return;
    }
  };

  return (
    <div className="date-input-container">
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <DatePicker
        customInput={<CustomInput data-ignore="true" />}
        selected={selected}
        onChange={handleChange}
        onChangeRaw={handleManualInput}
        onBlur={handleBlur}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        name={name}
        className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
          !selected ? 'text-gray-400' : 'text-gray-900'
        } ${className} ${
          error ? 'border-red-500' : ''
        }`}
        showMonthDropdown
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown={scrollableYearDropdown}
        yearDropdownItemNumber={yearDropdownItemNumber}
        isClearable
      />
      {error && (
        <p className="mt-1 text-sm text-red-600">
          {error}
        </p>
      )}
    </div>
  );
};

export default DateInput;
