import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { useAuth } from "../utils/auth";
import { useUser } from "../contexts/UserContext";
import { URL_PATHS } from "../utils/routeUtils";
import { submitContactForm } from "../utils/api";
import { UIUtils } from "../utils/uiUtils";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";

// Define the form data interface
interface ContactFormData {
  whoYouAre: "user" | "not_user" | "";
  helpWith: "application" | "technical" | "complaint" | "other" | "";
  name: string;
  email: string;
  confirmEmail: string;
  message: string;
}

// Define the error interface with string error messages
interface FormErrors {
  [key: string]: string | undefined;
}

const ContactUs: React.FC = () => {
  const { user } = useAuth();
  const { user: userData } = useUser();
  const navigate = useNavigate();
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(false);
    if (user) {
      setIsVisible(true);
    }
    // return () => setIsVisible(false);
  }, [setIsVisible,user]);
  // Form state
  const [formData, setFormData] = useState<ContactFormData>({
    whoYouAre: "",
    helpWith: "",
    name: "",
    email: "",
    confirmEmail: "",
    message: "",
  });
  
  // Form validation errors
  const [errors, setErrors] = useState<FormErrors>({});
  
  // Form submission state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");

  // Prefill form data if user is logged in
  useEffect(() => {
    if (user && userData) {
      setFormData(prev => ({
        ...prev,
        name: userData.first_name ? `${userData.first_name} ${userData.last_name || ""}`.trim() : "",
        email: userData.email || "",
        confirmEmail: userData.email || "",
      }));
    }
  }, [user, userData]);

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    
    // Clear error when field is edited
    if (errors[name as keyof ContactFormData]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  // Handle radio button selection for "Who you are"
  const handleWhoYouAreChange = (value: "user" | "not_user") => {
    setFormData(prev => ({
      ...prev,
      whoYouAre: value,
    }));
    
    // Clear error
    if (errors.whoYouAre) {
      setErrors(prev => ({
        ...prev,
        whoYouAre: undefined,
      }));
    }
  };

  // Handle radio button selection for "What can we help you with"
  const handleHelpWithChange = (value: "application" | "technical" | "complaint" | "other") => {
    setFormData(prev => ({
      ...prev,
      helpWith: value,
    }));
    
    // Clear error
    if (errors.helpWith) {
      setErrors(prev => ({
        ...prev,
        helpWith: undefined,
      }));
    }
  };

  // Validate form
  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    
    // If not logged in, validate "Who you are"
    if (!user && !formData.whoYouAre) {
      newErrors.whoYouAre = "Please select who you are";
    }
    
    // If not logged in and selected "not_user", validate other fields
    if (!user && formData.whoYouAre === "not_user") {
      if (!formData.helpWith) {
        newErrors.helpWith = "Please select what we can help you with";
      }
      
      if (!formData.name.trim()) {
        newErrors.name = "Please enter your name";
      }
      
      if (!formData.email.trim()) {
        newErrors.email = "Please enter your email";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = "Please enter a valid email address";
      }
      
      if (!formData.confirmEmail.trim()) {
        newErrors.confirmEmail = "Please confirm your email";
      } else if (formData.email !== formData.confirmEmail) {
        newErrors.confirmEmail = "Emails do not match";
      }
    }
    
    // If logged in, validate required fields
    if (user) {
      if (!formData.helpWith) {
        newErrors.helpWith = "Please select what we can help you with";
      }
      
      if (!formData.name.trim()) {
        newErrors.name = "Please enter your name";
      }
    }
    
    // Message is required for all users
    if (!formData.message.trim()) {
      newErrors.message = "Please enter your message";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    setSubmitError("");
    
    try {
      // Prepare contact data
      const contactData = {
        whoYouAre: user ? "user" : formData.whoYouAre, // For logged in users, always set as 'user'
        helpWith: formData.helpWith,
        name: formData.name,
        email: formData.email,
        message: formData.message,
      };
      
      // Log the form data being sent to the API
      console.log("Form data to be sent:", contactData);
      
      // Submit the contact form data to the backend
      await submitContactForm(contactData);
      
      // Track form submission
      UIUtils.trackButtonClick("Contact Form Submit");
      
      setSubmitSuccess(true);
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setSubmitError("There was an error submitting your message. Please try again later.");
      UIUtils.trackException("Contact form submission", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle login button click
  const handleLoginClick = () => {
    navigate(URL_PATHS.LOGIN + '?redirectTo=/contact-us');
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      {/* Breadcrumb */}
      <nav className="flex mb-6 text-sm">
        <Link to="/" className="text-gray-500 hover:text-orange-500">Home</Link>
        <span className="mx-2 text-gray-400">/</span>
        <span className="text-gray-900">Contact Us</span>
      </nav>
      
      {/* Page heading */}
      <h1 className="text-3xl font-bold mb-8">Need help?</h1>
      
      {submitSuccess ? (
        <div className="bg-green-50 border border-green-200 rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold text-green-800 mb-2">Thank you for your message</h2>
          <p className="text-green-700 mb-4">
            We have received your enquiry and will get back to you as soon as possible.
          </p>
          <Button 
            text="Back to Home" 
            onClick={() => navigate("/")}
            backgroundColor="bg-green-600"
            className="hover:bg-green-700"
          />
        </div>
      ) : (
        <>
          {submitError && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
              <p className="text-red-700">{submitError}</p>
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-6 contact-form">
            {/* Who you are - only show if not logged in */}
            {!user && (
              <div>
                <h2 className="text-xl font-semibold mb-4">Please confirm who you are:</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div 
                    className={`border rounded-lg p-4 cursor-pointer transition-all ${
                      formData.whoYouAre === "user" 
                        ? "border-orange-500 bg-orange-50" 
                        : "border-gray-200 hover:border-orange-300"
                    }`}
                    onClick={() => handleWhoYouAreChange("user")}
                  >
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="user"
                        name="whoYouAre"
                        value="user"
                        checked={formData.whoYouAre === "user"}
                        onChange={() => handleWhoYouAreChange("user")}
                        className="mr-2"
                      />
                      <label htmlFor="user" className="font-medium cursor-pointer">A user</label>
                    </div>
                  </div>
                  
                  <div 
                    className={`border rounded-lg p-4 cursor-pointer transition-all ${
                      formData.whoYouAre === "not_user" 
                        ? "border-orange-500 bg-orange-50" 
                        : "border-gray-200 hover:border-orange-300"
                    }`}
                    onClick={() => handleWhoYouAreChange("not_user")}
                  >
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="not_user"
                        name="whoYouAre"
                        value="not_user"
                        checked={formData.whoYouAre === "not_user"}
                        onChange={() => handleWhoYouAreChange("not_user")}
                        className="mr-2"
                      />
                      <label htmlFor="not_user" className="font-medium cursor-pointer">Not a user</label>
                    </div>
                  </div>
                </div>
                {errors.whoYouAre && (
                  <p className="text-red-600 text-sm mt-1">{errors.whoYouAre}</p>
                )}
              </div>
            )}
            
            {/* Show login prompt if user selected */}
            {!user && formData.whoYouAre === "user" && (
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-blue-800 mb-2">Please login to continue</h3>
                <p className="text-blue-700 mb-4">
                  To better assist you, please login to your account so we can access your information.
                </p>
                <Button 
                  text="Login" 
                  onClick={handleLoginClick}
                  backgroundColor="bg-blue-600"
                  className="hover:bg-blue-700"
                />
              </div>
            )}
            
            {/* Show form if not_user selected or user is logged in */}
            {(user || formData.whoYouAre === "not_user") && (
              <>
                {/* What can we help you with */}
                <div>
                  <h2 className="text-xl font-semibold mb-4">What can we help you with?</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div 
                      className={`border rounded-lg p-4 cursor-pointer transition-all ${
                        formData.helpWith === "application" 
                          ? "border-orange-500 bg-orange-50" 
                          : "border-gray-200 hover:border-orange-300"
                      }`}
                      onClick={() => handleHelpWithChange("application")}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="application"
                          name="helpWith"
                          value="application"
                          checked={formData.helpWith === "application"}
                          onChange={() => handleHelpWithChange("application")}
                          className="mr-2"
                        />
                        <label htmlFor="application" className="font-medium cursor-pointer">Complete an application</label>
                      </div>
                    </div>
                    
                    <div 
                      className={`border rounded-lg p-4 cursor-pointer transition-all ${
                        formData.helpWith === "technical" 
                          ? "border-orange-500 bg-orange-50" 
                          : "border-gray-200 hover:border-orange-300"
                      }`}
                      onClick={() => handleHelpWithChange("technical")}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="technical"
                          name="helpWith"
                          value="technical"
                          checked={formData.helpWith === "technical"}
                          onChange={() => handleHelpWithChange("technical")}
                          className="mr-2"
                        />
                        <label htmlFor="technical" className="font-medium cursor-pointer">Technical issues</label>
                      </div>
                    </div>
                    
                    <div 
                      className={`border rounded-lg p-4 cursor-pointer transition-all ${
                        formData.helpWith === "complaint" 
                          ? "border-orange-500 bg-orange-50" 
                          : "border-gray-200 hover:border-orange-300"
                      }`}
                      onClick={() => handleHelpWithChange("complaint")}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="complaint"
                          name="helpWith"
                          value="complaint"
                          checked={formData.helpWith === "complaint"}
                          onChange={() => handleHelpWithChange("complaint")}
                          className="mr-2"
                        />
                        <label htmlFor="complaint" className="font-medium cursor-pointer">Making a complaint</label>
                      </div>
                    </div>
                    
                    <div 
                      className={`border rounded-lg p-4 cursor-pointer transition-all ${
                        formData.helpWith === "other" 
                          ? "border-orange-500 bg-orange-50" 
                          : "border-gray-200 hover:border-orange-300"
                      }`}
                      onClick={() => handleHelpWithChange("other")}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="other"
                          name="helpWith"
                          value="other"
                          checked={formData.helpWith === "other"}
                          onChange={() => handleHelpWithChange("other")}
                          className="mr-2"
                        />
                        <label htmlFor="other" className="font-medium cursor-pointer">Something else</label>
                      </div>
                    </div>
                  </div>
                  {errors.helpWith && (
                    <p className="text-red-600 text-sm mt-1">{errors.helpWith}</p>
                  )}
                </div>
                
                {/* Alert box for complaints */}
                {formData.helpWith === "complaint" && (
                  <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
                    <p className="text-gray-700">
                      Download our full complaints handling procedure <a href="https://afforda.co.uk/wp-content/uploads/2024/03/Afforda-Complaints-Handling-Procedure.pdf" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">here</a>.
                    </p>
                  </div>
                )}
                
                {/* Name */}
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${
                      errors.name ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.name && (
                    <p className="text-red-600 text-sm mt-1">{errors.name}</p>
                  )}
                </div>
                
                {/* Email - if logged in, show as disabled */}
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={!!user}
                    className={`w-full px-4 py-2 border rounded-lg ${
                      user 
                        ? "bg-gray-100 text-gray-500 cursor-not-allowed" 
                        : "focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                    } ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-600 text-sm mt-1">{errors.email}</p>
                  )}
                </div>
                
                {/* Confirm Email - only show if not logged in */}
                {!user && (
                  <div>
                    <label htmlFor="confirmEmail" className="block text-sm font-medium text-gray-700 mb-1">
                      Confirm Email
                    </label>
                    <input
                      type="email"
                      id="confirmEmail"
                      name="confirmEmail"
                      value={formData.confirmEmail}
                      onChange={handleInputChange}
                      className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${
                        errors.confirmEmail ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.confirmEmail && (
                      <p className="text-red-600 text-sm mt-1">{errors.confirmEmail}</p>
                    )}
                  </div>
                )}
                
                {/* Message */}
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    How can we help you?
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={5}
                    className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${
                      errors.message ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                  {errors.message && (
                    <p className="text-red-600 text-sm mt-1">{errors.message}</p>
                  )}
                </div>
                
                {/* Submit button */}
                <div>
                  <Button
                    text="Send message"
                    type="submit"
                    onClick={() => {}}
                    isLoading={isSubmitting}
                    loadingText="Sending..."
                    disabled={isSubmitting}
                    className="w-full md:w-auto"
                  />
                </div>
              </>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default ContactUs;
