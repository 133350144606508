import React, { Component, ErrorInfo } from "react";
import { useArticles } from "../hooks/useArticles";
import { AccessTime } from "@mui/icons-material";
import Loader from "./Loader";
import { UIUtils } from "../utils/uiUtils";

interface Article {
  id: number;
  title: string;
  authorName: string;
  authorImageUrl: string;
  articleImageUrl: string;
  summary: string;
  articleUrl: string;
  authorBioUrl: string;
  readTime: string;
  publishedTime: string;
  updatedTime: string;
}

interface ArticleItemCardProps {
  article: Article;
}

const authorImageMap: Record<string, string> = {
  "Chris Wheal":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Chris-Wheal.svg",
  "Emma Lunn": "https://afforda.co.uk/wp-content/uploads/2024/04/Emma-Lunn.svg",
  "Rachel Wait":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Rachel-Wait.svg",
  "Rebecca Goodman":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Rebecca-Goodman.svg",
  "Harriet Meyer":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Harriet-Meyer.svg",
  "Ruth Emery":
    "https://afforda.co.uk/wp-content/uploads/2024/04/Ruth-Emery.svg",
  "Adrian Holliday":
    "https://afforda.co.uk/wp-content/uploads/2024/06/adrian-holliday.svg",
  "Samantha Downes":
    "https://afforda.co.uk/wp-content/uploads/2024/06/Samantha-Downes.svg",
};

// Error boundary component to catch and handle errors
class ArticleErrorBoundary extends Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    UIUtils.trackException("ArticleList Error", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return null; // Silently fail as ArticleList is non-mandatory
    }

    return this.props.children;
  }
}

const ArticleItemCard: React.FC<ArticleItemCardProps> = ({ article }) => {
  const handleClick = () => {
    try {
      window.open(article.articleUrl, "_blank");
    } catch (error) {
      UIUtils.trackException("Article link error", error);
    }
  };

  // Safely access article properties with fallbacks
  const safeArticle = {
    title: article?.title || "Untitled Article",
    authorName: article?.authorName || "Unknown Author",
    readTime: article?.readTime || "Unknown read time",
    summary: article?.summary || "No summary available",
    articleUrl: article?.articleUrl || "#",
  };

  return (
    <div
      onClick={handleClick}
      className="bg-white rounded-lg shadow-md p-4 mb-4 cursor-pointer hover:shadow-lg transition-shadow"
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <h3 className="text-lg font-bold mb-2">{safeArticle.title}</h3>
            <div className="flex items-center mb-2">
              <img
                src={
                  authorImageMap[safeArticle.authorName] ||
                  "https://afforda.co.uk/wp-content/uploads/2024/11/generic-avatar.png"
                }
                alt={safeArticle.authorName}
                className="w-8 h-8 rounded-full mr-2"
              />
              <span className="text-sm text-blue-600 font-semibold">
                by {safeArticle.authorName}
              </span>
            </div>
          </div>
          <div
            className="flex items-center"
            title="Estimated time to read this article"
          >
            <AccessTime className="w-4 h-4 mr-1" />
            <span className="text-sm">{safeArticle.readTime}</span>
          </div>
        </div>

        <p className="mb-2 line-clamp-3">{safeArticle.summary}</p>

        <button
          onClick={(e) => {
            e.stopPropagation();
            try {
              window.open(safeArticle.articleUrl, "_blank");
            } catch (error) {
              UIUtils.trackException("Article link error", error);
            }
          }}
          className="text-blue-600 underline text-sm text-left hover:text-blue-700"
        >
          Read more
        </button>
      </div>
    </div>
  );
};

interface ArticleListProps {
  userGoalStatement: string;
}

const ArticleListContent: React.FC<ArticleListProps> = ({ userGoalStatement }) => {
  const { articles, loading, error } = useArticles(userGoalStatement);

  if (loading) {
    return <Loader />;
  }

  if (error || !Array.isArray(articles)) {
    return null;
  }

  if (articles.length === 0) {
    return (
      <div className="text-center p-4">
        Oops! We couldn't find any articles related to your search at the
        moment. Don't worry, our team is working hard to curate more relevant
        content for you. Stay tuned for updates!
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-bold mb-5">
        Here are some articles we think might be relevant for you
      </h2>
      <div className="space-y-4">
        {articles.map((article) => (
          <ArticleItemCard key={article?.id || Math.random()} article={article} />
        ))}
      </div>
    </div>
  );
};

const ArticleList: React.FC<ArticleListProps> = (props) => (
  <ArticleErrorBoundary>
    <ArticleListContent {...props} />
  </ArticleErrorBoundary>
);

export default ArticleList;
