import React from "react";

interface SmallLoaderProps {
  message?: string;
  className?: string;
  spinnerClassName?: string;
  messageClassName?: string;
}

const SmallLoader: React.FC<SmallLoaderProps> = ({
  message,
  className,
  spinnerClassName = "animate-spin rounded-full h-8 w-8",
  messageClassName,
}) => {
  return (
    <div className={className} role="status">
      <div className={spinnerClassName} data-testid="spinner"></div>
      {message && <p className={messageClassName}>{message}</p>}
    </div>
  );
};

export default SmallLoader;
