// URL path constants
export const URL_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  EMAIL_VERIFICATION: '/email-verification/:totpUID',
  LEAD_LANDING: '/tell-us-more',
  EXT_LANDING: '/explore-options',
  PRIVACY_CONSENT: '/privacy-consent',
  CUSTOMER_HOME: '/customer-home',
  GETTING_STARTED: '/getting-started',
  QUICK_RESULTS: '/quick-results',
  BENEFITS_SUMMARY: '/benefits-summary',
  FULL_BENEFITS: '/full-benefits',
  LOANS_LANDING: '/loans-landing',
  APPLY: '/apply',
  ASK_AFFORDA: '/ask-afforda',
  SUGGESTIONS: '/suggestions',
  REVIEW_ACTIONS: '/review-actions',
  PREFERENCES: '/preferences',
  DISCOUNTS: '/discounts',
  REGRET: '/regret',
  CONTACT_US: '/contact-us',
  UNSUBSCRIBE: '/unsubscribe/:customerUID',
  LOGOFF: '/logoff',
  ERROR_404: '*'
} as const;

type URLPaths = typeof URL_PATHS;
type StaticURLPath = Exclude<URLPaths[keyof URLPaths], '*' | `${string}:${string}`>;

// Derive valid redirect paths by filtering out dynamic routes and special paths
const validRedirectPaths: StaticURLPath[] = Object.values(URL_PATHS).filter(
  (path): path is StaticURLPath => !path.includes(':') && path !== '*'
);

/**
 * Validates if a given URL is a valid redirect path in the app
 * @param url The URL to validate
 * @returns The URL if valid, undefined otherwise
 */
export const validateRedirectUrl = (url: string | null): string | undefined => {
  if (!url || !url.startsWith('/')) {
    return undefined;
  }

  // Check if the URL matches any of our valid redirect paths
  if (!validRedirectPaths.includes(url as StaticURLPath)) {
    return undefined;
  }

  return url;
};
