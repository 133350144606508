import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { UIUtils } from "../utils/uiUtils";
import { useBottomNavBar } from "../contexts/BottomNavBarContext";
import { getLoansPath } from "../utils/navigationUtils";
import { UserGoal } from "../utils/types";
import BenefitsBuilder from "../components/BenefitsBuilder";
import Loader from "../components/Loader";
import { Button } from "../components/Button";
import { UserGoalStatement } from "../components/UserGoalStatement";

const CustomerHome: React.FC = () => {
  const navigate = useNavigate();
  const {
    user,
    userGoal,
    benefitsSummary,
    isLoading,
    error,
    setSaveOnShopping,
  } = useUser();
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  const countOpenGoals = (goalData: UserGoal | null) => {
    const actions = goalData?.goal_actions || [];
    return actions.filter((action) => !action.is_done).length;
  };

  if (isLoading) {
    return <Loader message="Loading your dashboard..." />;
  }

  if (error) {
    UIUtils.showMessageOnSnackBar(
      "An error occurred while fetching user data."
    );
    navigate("/");
    return null;
  }

  const firstName = user?.first_name || "";
  const openGoals = countOpenGoals(userGoal);

  const handleSaveOnShoppingClick = () => {
    setSaveOnShopping();
    navigate("/discounts");
  };

  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-xl mb-2">Welcome {firstName}!</h1>

      {userGoal && (
        <div className="mb-8">
          <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">
            Your Goal
          </h2>
          <div className="my-4">
            <UserGoalStatement
              goalStatement={userGoal.goal_statement}
              goalActions={userGoal.goal_actions.map((action) => ({
                goalAction: action.goal_action,
              }))}
              timeframe={userGoal.response.timeframe}
              riskScore={userGoal.response.risk_score}
              parentGoalId={userGoal.user_goal_id}
              showFeedbackInput={true}
            />
          </div>
          <div className="mt-4">
            {openGoals > 0 ? (
              <p>
                You have {openGoals} suggestions to act on.{" "}
                <div className="mt-2 flex justify-center">
                  <Button
                    text="Take me to them"
                    onClick={() => navigate("/suggestions")}
                  />
                </div>
              </p>
            ) : (
              <p>
                Congratulations {firstName}! You've completed all the actions
                for the above goal!
              </p>
            )}
          </div>
          <p className="mt-2">
            Looking to set a new goal?{" "}
            <span
              className="text-secondary font-bold underline cursor-pointer"
              onClick={() => navigate("/ask-afforda")}
            >
              Start a new goal.
            </span>
          </p>
        </div>
      )}

      <div className="mb-8">
        <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">Benefits</h2>
        <BenefitsBuilder benefitsSummary={benefitsSummary} useButtons={true} />
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">
          Save on shopping
        </h2>
        <p className="mt-2">
          Afforda lets you save at the checkout in a number of high street
          stores.
        </p>
        <div className="mt-2 flex justify-center">
          <Button text="Save on shopping" onClick={handleSaveOnShoppingClick} />
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-bold bg-blue-300 p-2 rounded">
          Credit options
        </h2>
        <p className="mt-2">
          We can also help you search for loans and other credit products.
        </p>
        <div className="mt-2 flex justify-center">
          <Button text="Learn more" onClick={() => navigate(getLoansPath())} />
        </div>
        <p className="text-center font-bold mt-2">Representative 1333%APR</p>
      </div>

      <div className="text-center border-t">
        <h2 className="text-xl mb-2 mt-5">Not sure where to start?</h2>
        <Button text="Ask Afforda" onClick={() => navigate("/ask-afforda")} />
      </div>
    </div>
  );
};

export default CustomerHome;
