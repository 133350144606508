import React from "react";
import { UIUtils } from "../utils/uiUtils";

interface ButtonProps {
  /** The text content of the button */
  text: string;
  /** Function to be called when button is clicked */
  onClick: () => void;
  /** Text to show when button is in loading state */
  loadingText?: string;
  /** Whether the button is in loading state */
  isLoading?: boolean;
  /** Custom background color class */
  backgroundColor?: string;
  /** Custom text color class */
  textColor?: string;
  /** Type of the button */
  type?: "button" | "submit" | "reset";
  /** Additional CSS classes */
  className?: string;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Title attribute for tooltip and accessibility */
  title?: string;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  loadingText = "Loading...",
  isLoading = false,
  backgroundColor = "bg-orange-500",
  textColor = "text-white",
  type = "button",
  className = "",
  disabled = false,
  title,
}) => {
  const handleClick = () => {
    UIUtils.trackButtonClick(text);
    onClick();
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={isLoading || disabled}
      title={title}
      className={`
        ${backgroundColor} 
        ${textColor}
        px-6 
        py-2 
        rounded-lg
        font-bold
        text-base
        disabled:bg-neutral-100
        disabled:text-neutral-400
        disabled:cursor-not-allowed
        transition-all
        duration-200
        hover:bg-orange-600 
        focus:outline-none
        focus:ring  
        focus:ring-orange-500
        focus:ring-offset-2
        custom-shadow
        ${className}
      `}
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span>{loadingText}</span>
        </div>
      ) : (
        text
      )}
    </button>
  );
};
